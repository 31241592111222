<div class="org-card" [ngClass]="incumbent ? 'incumbent-border' : ''">
  @if (incumbent) {
    <div class="incumbent-label">Incumbent</div>
  }
  <div class="org-card-grid">
    <ng-content select="[checkbox]"></ng-content>
    <div class="org-card-info-stack">
      <div class="org-card-header">
        <div class="org-card-logo">
          <app-org-logo [org]="orgInfo"></app-org-logo>
        </div>
        <ng-content select="[top-right]"></ng-content>
      </div>
      <div class="org-card-name-grid">
        <span class="org-card-name">{{ orgInfo.name }}</span>
        <app-org-certified-icon [rmtCertification]="orgInfo.rmtCertification"></app-org-certified-icon>
      </div>
      @if (showProfileLink) {
        <button class="btn-reset btn-anchor org-view-profile" (click)="displayRfiProfile()">
          {{ viewProfileLabel }}
        </button>
      }
    </div>
  </div>
</div>
