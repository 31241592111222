<div class="compare-pbms-container">
  <div class="compare-pbms-labels">
    <div class="compare-pbms-org-header-spacer"></div>
    @if (includeCompanyOverview) {
      <div class="compare-pbm-company-overview-labels">
        <div class="compare-pbms-rfi-question-section-label">Company Overview</div>
        <div class="compare-pbms-rfi-question-label-text-area">Overview</div>
        <div class="compare-pbms-rfi-question-label-text-area">Specialties</div>
        <div class="compare-pbms-question-label">Headquarters</div>
        <div class="compare-pbms-question-label">Company size</div>
        <div class="compare-pbms-question-label">Founded</div>
        <div class="compare-pbms-question-label">Ownership Structure</div>
        <div class="compare-pbms-question-label">
          <fa-icon class="pr-1" [style.color]="'#574f77'" [icon]="faGlobeAmericas"></fa-icon>
          Website
        </div>
        <div class="compare-pbms-question-label">
          <fa-icon class="pr-1" [style.color]="'#574f77'" [icon]="faLinkedin"></fa-icon>
          LinkedIn
        </div>
        <div class="compare-pbms-question-label">
          <fa-icon class="pr-1" [style.color]="'#574f77'" [icon]="faFacebookSquare"></fa-icon>
          Facebook
        </div>
        <div class="compare-pbms-question-label">
          <fa-icon class="pr-1" [style.color]="'#574f77'" [icon]="faTwitterSquare"></fa-icon>
          X (Formerly Twitter)
        </div>
        <div class="compare-pbms-question-label">
          <fa-icon class="pr-1" [style.color]="'#574f77'" [icon]="faInstagram"></fa-icon>
          Instagram
        </div>
      </div>
    }
    <div class="compare-pbms-rfi-question-labels">
      @for (rfiQuestion of sortedRfiQuestionsToShow; track rfiQuestion) {
        <div
          [matTooltip]="rfiQuestion.formFieldDefinition.name"
          [matTooltipShowDelay]="500"
          [ngClass]="{
            'compare-pbms-rfi-question-section-label': rfiQuestion.formFieldDefinition.type === 'SECTION_HEADER',
            'compare-pbms-rfi-question-section-label-padding':
              rfiQuestion.formFieldDefinition.type === 'SECTION_HEADER',
            'compare-pbms-question-label':
              rfiQuestion.formFieldDefinition.type !== 'SECTION_HEADER' && !isTextArea(rfiQuestion.formFieldDefinition),
            'text-no-overflow':
              rfiQuestion.formFieldDefinition.type !== 'SECTION_HEADER' && !isTextArea(rfiQuestion.formFieldDefinition),
            'compare-pbms-rfi-question-label': rfiQuestion.formFieldDefinition.type !== 'SECTION_HEADER',
            'compare-pbms-rfi-question-label-text-area': isTextArea(rfiQuestion.formFieldDefinition)
          }"
        >
          {{ rfiQuestion.formFieldDefinition.name }}
        </div>
      }
    </div>
  </div>
  @if (loading) {
    <div class="compare-pbms-loading">
      <app-in-progress-indicator diameter="1.25rem"></app-in-progress-indicator>
    </div>
  }
  @if (rfiResponses$ | async) {
    <div
      class="compare-pbm-values-dl"
      cdkDropList
      cdkDropListOrientation="horizontal"
      [cdkDropListAutoScrollStep]="15"
      [cdkDropListAutoScrollDisabled]="false"
      [cdkDropListData]="rfiResponses"
      (cdkDropListDropped)="itemOrderChanged($event)"
    >
      @for (rfiResponse of rfiResponses; track rfiResponse) {
        <app-rfi-vertical-panel
          cdkDrag
          [includeCompanyOverview]="includeCompanyOverview"
          [rfiResponse]="rfiResponse"
          [rfiQuestionsToShow]="sortedRfiQuestionsToShow"
        >
          <div class="move-handle" panel-handle>
            <fa-icon cdkDragHandle [icon]="faGripDots" class="color-medium-gray"></fa-icon>
          </div>
          <div class="panel-remove" panel-action>
            <fa-icon
              [icon]="timesIcon"
              class="cursor-click color-medium-gray"
              (click)="removeRfiResponse(rfiResponse.orgId)"
            ></fa-icon>
          </div>
        </app-rfi-vertical-panel>
      }
    </div>
  }
</div>
