import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SERVER_API_URL } from '@app/app.constants';
import { OrgTypeModel } from '@common/models/org-type.model';
import { Page } from '@common/models/page.model';
import { FormModel } from '@form-lib/models/form.model';
import { US_STATES } from '@form-lib/predefined/geographic.options';
import { DataDefModel } from '@lib-resource/data-def.model';
import { UrlUtilService } from './url-util.service';

@Injectable({
  providedIn: 'root'
})
export class OrgTypeService {
  constructor(
    private http: HttpClient,
    private urlUtil: UrlUtilService
  ) {}

  getOrgTypes(query: string): Observable<Page<OrgTypeModel>> {
    return this.http.get(`${SERVER_API_URL}/org-type`, {
      params: this.urlUtil.buildSearchParams(query, 0, 100, ['name'])
    });
  }

  getById(id: number): Observable<OrgTypeModel> {
    return this.http.get(`${SERVER_API_URL}/org-type/${id}`);
  }
}
