import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { Page } from '@common/models/page.model';
import { UrlUtilService } from '@common/services/url-util.service';
import { FileModel } from '@file-upload-lib/file.model';
import {
  CredentialedOrgModel,
  DiscoveredOrgModel,
  OrgFeaturesModel,
  OrgModel,
  OrgNotification
} from '@main/org/models/org.model';
import { OrgMemberships } from '@main/store/org-membership/org-membership.actions';
import { SiteFilterSelectors } from '@main/store/site-filter/site-filter.selectors';
import { Store } from '@ngxs/store';
import { AdminFeeModel } from '@quote/model/admin-fee.model';
import { Observable } from 'rxjs';
import { map, shareReplay, take, tap } from 'rxjs/operators';
import { FileDownloadService } from '@shared/components/file-download-progress/file-download.service';

@Injectable({
  providedIn: 'root'
})
export class OrgHttpService {
  // cache org images from the server to avoid the round trips for the exact same data
  private orgImageCache: Map<number, Observable<Blob>> = new Map<number, Observable<Blob>>();
  private orgImageCachExpire = new Date().getTime() + 21600000; // refresh the cache every 6 hrs

  get _firstOrgId() {
    return this.store.selectSnapshot(SiteFilterSelectors.singleSelectedOrgId);
  }

  get baseUrl() {
    return `${SERVER_API_URL}/org/${this._firstOrgId}`;
  }

  constructor(
    protected http: HttpClient,
    private urlUtil: UrlUtilService,
    private store: Store,
    private fileDownloadService: FileDownloadService
  ) {}

  orgNameExists(id: number, name: string): Observable<boolean> {
    let params = new HttpParams(
      this.urlUtil.buildOption({
        fromObject: {
          name: name
        }
      })
    );
    if (id) {
      params = params.set('id', `${id}`);
    }
    return this.http.get<boolean>(`${SERVER_API_URL}/org/exists`, { params: params });
  }

  searchAllOrgs(query: string, page: number, pageSize: number, sort: string[]): Observable<Page<OrgModel>> {
    return this.http.get(`${SERVER_API_URL}/org`, {
      params: this.urlUtil.buildSearchParams(query, page, pageSize, sort)
    });
  }

  updateOrg(org: OrgModel): Observable<OrgModel> {
    return this.http.put<OrgModel>(`${SERVER_API_URL}/org/${org.id}`, org).pipe(
      map((res) => {
        this.store.dispatch(new OrgMemberships.Get());
        return res;
      })
    );
  }

  getDrcs(orgId): Observable<any[]> {
    return this.http.get<string[]>(`${SERVER_API_URL}/org/${orgId}/diag-related-code`);
  }

  updateDrcs(orgId, codes: string[]): Observable<string[]> {
    return this.http.put<string[]>(`${SERVER_API_URL}/org/${orgId}/diag-related-code`, codes);
  }

  getCredentialedOrgs(orgId): Observable<CredentialedOrgModel[]> {
    return this.http.get<CredentialedOrgModel[]>(`${SERVER_API_URL}/org/${orgId}/credentialed`);
  }

  updateCredentialedOrgs(orgId, credentialedOrgIds: CredentialedOrgModel[]): Observable<CredentialedOrgModel[]> {
    return this.http.put<CredentialedOrgModel[]>(`${SERVER_API_URL}/org/${orgId}/credentialed`, credentialedOrgIds);
  }

  createOrg(org: OrgModel, membership: boolean): Observable<OrgModel> {
    const memberflag = membership ? '' : '?membership=false'; // defaults to true
    return this.http.post(`${SERVER_API_URL}/org${memberflag}`, org);
  }

  getById(id: number): Observable<OrgModel> {
    return this.http
      .get<OrgModel>(`${SERVER_API_URL}/org/${id}`)
      .pipe(map((org) => (org.features ? org : { ...org, features: [] })));
  }

  delete(id: number): Observable<OrgModel> {
    return this.http.delete(`${SERVER_API_URL}/org/${id}`).pipe(
      tap((_) => {
        this.store.dispatch(new OrgMemberships.Get());
      })
    );
  }

  getOrgFeatures(orgId): Observable<OrgFeaturesModel> {
    return this.http.get<OrgFeaturesModel>(`${SERVER_API_URL}/org/${orgId}/feature`);
  }

  updateOrgFeatures(orgId, featureUpdateModel: OrgFeaturesModel): Observable<OrgFeaturesModel> {
    return this.http.put<OrgFeaturesModel>(`${SERVER_API_URL}/org/${orgId}/feature`, featureUpdateModel);
  }

  // Must have ability to get another org's admin fees
  getAdminFees(orgId?: number): Observable<AdminFeeModel[]> {
    if (!orgId) {
      orgId = this._firstOrgId;
    }
    return this.http.get<AdminFeeModel[]>(`${SERVER_API_URL}/org/${orgId}/adminfee`);
  }

  saveAdminFees(adminFees: Array<AdminFeeModel>): Observable<AdminFeeModel[]> {
    return this.http.put<AdminFeeModel[]>(`${this.baseUrl}/adminfee`, adminFees);
  }

  findOrgFiles(pageIndex, pageSize = 20): Observable<Page<FileModel>> {
    return this.http.get(`${this.baseUrl}/supportfile`, {
      params: this.urlUtil.buildSearchParams(null, pageIndex, pageSize, null)
    });
  }

  deleteOrgFile(fileId: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/supportfile/${fileId}`);
  }

  downloadOrgFile(fileId: string, fileName: string): Observable<Blob> {
    return this.fileDownloadService.downloadFile(`${this.baseUrl}/supportfile/${fileId}/stream`, { fileName });
  }

  getLogoImage(orgId: number, quoteResponseId?: number): Observable<Blob> {
    if (this.orgImageCachExpire < new Date().getTime()) {
      // cache expired
      this.orgImageCache.clear();
      this.orgImageCachExpire = new Date().getTime() + 21600000; // refresh the cache every 6 hrs
    }

    if (this.orgImageCache.has(orgId)) {
      return this.orgImageCache.get(orgId);
    }

    // either fetch from the org or through the quote response, depending on if the quote resonse id is provided.  This is for the case where
    //  the org is no longer credentialed to the requesting side, but there is still a response to view
    this.orgImageCache.set(
      orgId,
      quoteResponseId
        ? this.http
            .get(`${SERVER_API_URL}/quote-response/${quoteResponseId}/org-logo`, { responseType: 'blob' })
            .pipe(take(1), shareReplay({ refCount: false, bufferSize: 1 }))
        : this.http
            .get(`${SERVER_API_URL}/org/${orgId}/logo`, { responseType: 'blob' })
            .pipe(take(1), shareReplay({ refCount: false, bufferSize: 1 }))
    );

    return this.orgImageCache.get(orgId);
  }

  updateLogoImage(orgId: number, file: File): Observable<FileModel> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http
      .put<FileModel>(`${SERVER_API_URL}/org/${orgId}/logo`, formData)
      .pipe(tap((_) => this.orgImageCache.delete(orgId)));
  }

  deleteLogoImage(orgId: number): Observable<any> {
    return this.http.delete(`${SERVER_API_URL}/org/${orgId}/logo`).pipe(tap((_) => this.orgImageCache.delete(orgId)));
  }

  getOrgNotifications(orgId): Observable<OrgNotification[]> {
    return this.http.get<OrgNotification[]>(`${SERVER_API_URL}/org/${orgId}/notification`);
  }

  setOrgNotifications(orgId, orgNotifications: OrgNotification[]): Observable<OrgNotification[]> {
    return this.http.put<OrgNotification[]>(`${SERVER_API_URL}/org/${orgId}/notification`, orgNotifications);
  }

  findDuplicateCredentialed(credOrgs: CredentialedOrgModel[]): string {
    if (credOrgs) {
      const mapped = new Set<number>();
      for (let cred of credOrgs) {
        if (mapped.has(cred.credentialedOrg.id)) {
          return cred.credentialedOrg.name;
        }
        mapped.add(cred.credentialedOrg.id);
      }
    }
    return null;
  }

  discoverPBMsForOrg(
    orgId: number,
    page: number,
    pageSize: number,
    sort?: string[]
  ): Observable<Page<DiscoveredOrgModel>> {
    return this.http.get(`${SERVER_API_URL}/org/${orgId}/discover-pbms`, {
      params: this.urlUtil.buildSearchParams(undefined, page, pageSize, sort)
    });
  }
}
