import { Component, Input, OnInit } from '@angular/core';
import { RfiFieldModel } from '@common/models/rfi-template.model';
import { RfiService } from '@common/services/rfi.service';
import { Observable } from 'rxjs';
import { RfiResponseModel } from '@common/models/rfi-response.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { faGlobeAmericas, faGripDotsVertical, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { finalize, tap } from 'rxjs/operators';
import { faFacebookSquare, faInstagram, faLinkedin, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { FormFieldDefinitionModel } from '@common/models/form-field-definition.model';
import { FormFieldType } from '@common/models/quote-type.model';
import { SelectedPBM } from '@common/dialogs/compare-pbms-dialog/compare-pbms-dialog.definitions';

@Component({
  selector: 'app-compare-pbms',
  templateUrl: './compare-pbms.component.html',
  styleUrls: ['./compare-pbms.component.scss', '../compare-pbms-dialog.component.scss']
})
export class ComparePbmsComponent implements OnInit {
  @Input() brokerId: number;
  @Input() pbmsToCompare: SelectedPBM[];
  @Input() rfiQuestionsToShow: RfiFieldModel[];
  @Input() includeCompanyOverview: boolean = true;

  rfiResponses$: Observable<RfiResponseModel[]>;
  sortedRfiQuestionsToShow: RfiFieldModel[];
  rfiResponses: RfiResponseModel[];
  faGripDots = faGripDotsVertical;
  timesIcon = faTimes;
  faGlobeAmericas = faGlobeAmericas;
  faFacebookSquare = faFacebookSquare;
  faInstagram = faInstagram;
  faLinkedin = faLinkedin;
  faTwitterSquare = faTwitterSquare;
  loading: boolean = false;

  constructor(private rfiService: RfiService) {}

  ngOnInit(): void {
    this.loading = true;
    this.pbmsToCompare.sort((a, b) => a.index - b.index).map((selectedPbm) => selectedPbm.pbm);
    this.rfiResponses$ = this.rfiService
      .getRfiResponseForRequestedOrgs(
        this.brokerId,
        this.pbmsToCompare.map((pbm) => pbm.pbm.id)
      )
      .pipe(
        tap((responses) => (this.rfiResponses = responses)),
        finalize(() => (this.loading = false))
      );
    this.sortedRfiQuestionsToShow = [...this.rfiQuestionsToShow].sort(
      (a, b) => a.formFieldDefinition.orderIndex - b.formFieldDefinition.orderIndex
    );
  }

  itemOrderChanged(event: CdkDragDrop<any, any>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }

  removeRfiResponse(toRemove: number): void {
    this.rfiResponses = this.rfiResponses.filter((response) => response.orgId !== toRemove);
  }

  isTextArea(def: FormFieldDefinitionModel): boolean {
    return def.type === FormFieldType.TEXT && def.inputSize > 1;
  }
}
