import { Observable, of } from 'rxjs';
import { Page } from '@common/models/page.model';
import { SERVER_API_URL } from '@app/app.constants';
import { CsvImportModel } from '@common/models/csv-import.model';
import { ImportResultModel } from '@common/models/import-result.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlUtilService } from '@common/services/url-util.service';
import { SearchModel } from '@common/models/search.model';
import { RxClaimModel } from '@common/models/rx-claim.model';
import { RetailMailAgg, RxClaimStatModel, RxClaimStatSummary, typeAgg } from '@common/models/rx-claim-stat.model';
import { FindRequestModel } from '@common/models/find-request.model';
import { HttpResourceService } from '@main/http-resource.service';
import { map, switchMap } from 'rxjs/operators';
import { AggResultModel } from '@common/models/agg.model';
import { DataFormatter } from '@lib-resource/data.formatter';
import { getValueByDef } from '@lib-resource/data.utils';
import { FileDownloadService } from '@shared/components/file-download-progress/file-download.service';
import { quoteResponseRxClaimManifestColumns } from '@quote-rx/rx-claim/rx-claim-list/rx-claim-list.definition';
import { BulkActionResultModel } from '@common/models/bulk-action.model';

@Injectable({
  providedIn: 'root'
})
export class RxClaimService {
  constructor(
    protected http: HttpClient,
    private httpResource: HttpResourceService,
    private urlUtil: UrlUtilService,
    private fileDownloadService: FileDownloadService
  ) {}

  searchRxClaimForRequest(quoteRequestId: number, searchModel: SearchModel): Observable<Page<RxClaimModel>> {
    return this.searchRxClaim(`${SERVER_API_URL}/quote-request-rx/${quoteRequestId}/rx-claim`, searchModel);
  }

  searchRxClaimForRequestQO(quoteRequestId: number, findReq: FindRequestModel): Observable<Page<RxClaimModel>> {
    return this.http.put<Page<RxClaimModel>>(`${SERVER_API_URL}/quote-request-rx/${quoteRequestId}/rx-claim`, findReq);
  }

  recalculateRxClaimStatForRequest(quoteRequestId: number): Observable<void> {
    return this.http.put<void>(`${SERVER_API_URL}/quote-request-rx/${quoteRequestId}/rx-claim-stat`, null);
  }

  uploadRxClaimForRequest(
    quoteRequestId: number,
    overwrite: boolean,
    file: File,
    csvModel: CsvImportModel
  ): Observable<ImportResultModel> {
    return this.uploadRxClaim(
      `${SERVER_API_URL}/quote-request-rx/${quoteRequestId}/rx-claim/import`,
      overwrite,
      file,
      csvModel
    );
  }

  searchRxClaimForResponse(quoteResponseId: number, searchModel: SearchModel): Observable<Page<RxClaimModel>> {
    return this.searchRxClaim(`${SERVER_API_URL}/quote-response-rx/${quoteResponseId}/rx-claim`, searchModel);
  }

  downloadRxClaimCsvForResponse(quoteResponseId: number, fileName: string): void {
    this.fileDownloadService.csvDownloadProgress(
      fileName,
      quoteResponseRxClaimManifestColumns.map((def) => def.label),
      (page) =>
        this.searchRxClaimForResponse(quoteResponseId, new SearchModel(null, null, ['ndc'], 1000, page)).pipe(
          map((page) => ({
            total: page.total,
            page: page.page,
            pageSize: page.pageSize,
            content: page.content.map((c) =>
              quoteResponseRxClaimManifestColumns.map((def) => DataFormatter.transform(getValueByDef(c, def), def))
            )
          }))
        )
    );
  }

  searchRxClaim(url: string, searchModel: SearchModel): Observable<Page<RxClaimModel>> {
    return this.http.get<Page<RxClaimModel>>(url, {
      params: this.urlUtil.buildSearchParams(
        searchModel.buildQueryString(),
        searchModel.pageIndex,
        searchModel.pageSize,
        searchModel.sort
      )
    });
  }

  uploadRxClaim(url: string, overwrite: boolean, file: File, csvModel: CsvImportModel): Observable<ImportResultModel> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('overwrite', `${overwrite}`);

    if (csvModel != null) {
      formData.append('fieldmap', new Blob([JSON.stringify(csvModel)], { type: 'application/json' }));
    }
    return this.http.post<ImportResultModel>(url, formData);
  }

  importRxClaimRxAuditClaims(quoteRequestRxId: number, query: string): Observable<ImportResultModel> {
    const formData: FormData = new FormData();
    formData.append('q', query);
    return this.http.post<ImportResultModel>(
      `${SERVER_API_URL}/quote-request-rx/${quoteRequestRxId}/rx-claim/import-rx-audit-claim`,
      formData
    );
  }

  findRxClaimStat(quoteRequestId: number): Observable<RxClaimStatModel[]> {
    return this.http.get<RxClaimStatModel[]>(`${SERVER_API_URL}/quote-request-rx/${quoteRequestId}/rx-claim-stat`);
  }

  calcRxClaimStats(id: number): Observable<RxClaimStatSummary> {
    return this.findRxClaimStat(id).pipe(
      switchMap((resp) => {
        const actual = resp.find((stat) => stat.statType === 'ACTUAL');
        if (!actual || actual?.processing) {
          return of({
            quality: {
              lastModifiedDate: actual?.lastModifiedDate,
              processing: actual?.processing
            }
          });
        }
        return this.searchRxClaimForRequestQO(actual?.quoteRequestId, typeAgg).pipe(
          switchMap((result) => of(this.parseAgg(actual, result)))
        );
      })
    );
  }

  private parseAgg(claimStat: RxClaimStatModel, typeAgg: Page<RxClaimModel>): RxClaimStatSummary {
    const retail30: RetailMailAgg = new RetailMailAgg();
    const retail90: RetailMailAgg = new RetailMailAgg();
    const mail: RetailMailAgg = new RetailMailAgg();
    const specialtyRetailAgg: RetailMailAgg = new RetailMailAgg();
    const specialtyMailAgg: RetailMailAgg = new RetailMailAgg();
    const limitedAgg = new RetailMailAgg();

    typeAgg?.aggResults[0]?.groups?.forEach((g) => {
      const medispanawp = this.getAggValue(g.aggs, 'medispanawp');
      const ingCost = this.getAggValue(g.aggs, 'ingcost');
      const retail30dispfee = this.getAggValue(g.aggs, 'retail30dispfee');
      const retail90dispfee = this.getAggValue(g.aggs, 'retail90dispfee');
      const maildispfee = this.getAggValue(g.aggs, 'maildispfee');
      const limiteddispfee = this.getAggValue(g.aggs, 'limiteddispfee');
      const specretaildispfee = this.getAggValue(g.aggs, 'specretaildispfee');
      const specmaildispfee = this.getAggValue(g.aggs, 'specmaildispfee');
      const cnt = this.getAggValue(g.aggs, 'cnt');
      switch (g.value) {
        case 'LIMITED_DIST_BRAND':
        case 'LIMITED_DIST_GENERIC': {
          // limited distribution brand and generic both flow into the same numbers.  Using the generic
          limitedAgg.genericAwp += medispanawp;
          limitedAgg.genericIngCost += ingCost;
          limitedAgg.dispFee += limiteddispfee;
          limitedAgg.genericCnt += cnt;
          break;
        }
        case 'MAIL_BRAND': {
          mail.brandAwp = medispanawp;
          mail.brandIngCost = ingCost;
          mail.brandEffDisc = medispanawp === 0 ? 0 : (1 - ingCost / medispanawp) * 100;
          mail.dispFee += maildispfee;
          mail.brandCnt += cnt;
          mail.cnt += cnt;
          mail.total += ingCost;
          break;
        }
        case 'MAIL_GENERIC': {
          mail.genericAwp = medispanawp;
          mail.genericIngCost = ingCost;
          mail.genericEffDisc = medispanawp === 0 ? 0 : (1 - ingCost / medispanawp) * 100;
          mail.dispFee += maildispfee;
          mail.genericCnt += cnt;
          mail.cnt += cnt;
          mail.total += ingCost;
          break;
        }
        case 'RETAIL30_BRAND': {
          retail30.brandAwp = medispanawp;
          retail30.brandIngCost = ingCost;
          retail30.brandEffDisc = medispanawp === 0 ? 0 : (1 - ingCost / medispanawp) * 100;
          retail30.dispFee += retail30dispfee;
          retail30.brandCnt += cnt;
          retail30.cnt += cnt;
          retail30.total += ingCost;
          break;
        }
        case 'RETAIL30_GENERIC': {
          retail30.genericAwp = medispanawp;
          retail30.genericIngCost = ingCost;
          retail30.genericEffDisc = medispanawp === 0 ? 0 : (1 - ingCost / medispanawp) * 100;
          retail30.dispFee += retail30dispfee;
          retail30.genericCnt = cnt;
          retail30.cnt += cnt;
          retail30.total += ingCost;
          break;
        }
        case 'RETAIL90_BRAND': {
          retail90.brandAwp = medispanawp;
          retail90.brandIngCost = ingCost;
          retail90.brandEffDisc = medispanawp === 0 ? 0 : (1 - ingCost / medispanawp) * 100;
          retail90.dispFee += retail90dispfee;
          retail90.brandCnt += cnt;
          retail90.cnt += cnt;
          retail90.total += ingCost;
          break;
        }
        case 'RETAIL90_GENERIC': {
          retail90.genericAwp = medispanawp;
          retail90.genericIngCost = ingCost;
          retail90.genericEffDisc = medispanawp === 0 ? 0 : (1 - ingCost / medispanawp) * 100;
          retail90.dispFee += retail90dispfee;
          retail90.genericCnt += cnt;
          retail90.cnt += cnt;
          retail90.total += ingCost;
          break;
        }
        case 'SPECIALTY_RETAIL_BRAND': {
          specialtyRetailAgg.brandAwp = medispanawp;
          specialtyRetailAgg.brandIngCost = ingCost;
          specialtyRetailAgg.brandEffDisc = medispanawp === 0 ? 0 : (1 - ingCost / medispanawp) * 100;
          specialtyRetailAgg.brandCnt = cnt;
          specialtyRetailAgg.dispFee += specretaildispfee;
          specialtyRetailAgg.cnt += cnt;
          specialtyRetailAgg.total += ingCost;
          break;
        }
        case 'SPECIALTY_MAIL_BRAND': {
          specialtyMailAgg.brandAwp = medispanawp;
          specialtyMailAgg.brandIngCost = ingCost;
          specialtyMailAgg.brandEffDisc = medispanawp === 0 ? 0 : (1 - ingCost / medispanawp) * 100;
          specialtyMailAgg.brandCnt = cnt;
          specialtyMailAgg.dispFee += specmaildispfee;
          specialtyMailAgg.cnt += cnt;
          specialtyMailAgg.total += ingCost;
          break;
        }
        case 'SPECIALTY_RETAIL_GENERIC': {
          specialtyRetailAgg.genericAwp = medispanawp;
          specialtyRetailAgg.genericIngCost = ingCost;
          specialtyRetailAgg.genericEffDisc = medispanawp === 0 ? 0 : (1 - ingCost / medispanawp) * 100;
          specialtyRetailAgg.dispFee += specretaildispfee;
          specialtyRetailAgg.genericCnt = cnt;
          specialtyRetailAgg.cnt += cnt;
          specialtyRetailAgg.total += ingCost;
          break;
        }
        case 'SPECIALTY_MAIL_GENERIC': {
          specialtyMailAgg.genericAwp = medispanawp;
          specialtyMailAgg.genericIngCost = ingCost;
          specialtyMailAgg.genericEffDisc = medispanawp === 0 ? 0 : (1 - ingCost / medispanawp) * 100;
          specialtyMailAgg.dispFee += specmaildispfee;
          specialtyMailAgg.genericCnt = cnt;
          specialtyMailAgg.cnt += cnt;
          specialtyMailAgg.total += ingCost;
          break;
        }
        default:
          break;
      }
    });

    if (limitedAgg.genericAwp !== 0 && limitedAgg.genericIngCost) {
      limitedAgg.genericEffDisc = (1 - limitedAgg.genericIngCost / limitedAgg.genericAwp) * 100;
    }

    return {
      quality: claimStat,
      retail30: retail30,
      retail90: retail90,
      mail: mail,
      specialtyRetail: specialtyRetailAgg,
      specialtyMail: specialtyMailAgg,
      limited: limitedAgg
    };
  }

  private getAggValue(aggs: Array<AggResultModel>, key: string): number {
    const val = aggs.filter((ag) => ag.name === key).map((ag) => ag.value);
    return val.length > 0 ? val[0] : 0;
  }

  bulkAction(quoteRequestId: number, action: string, ids: number[], query: string): Observable<BulkActionResultModel> {
    return this.httpResource.putBulkActionQueryWithoutOrg({
      path: `quote-request-rx/${quoteRequestId}/rx-claim/bulk-action`,
      query: query,
      ids: ids,
      action: action
    });
  }
}
