import { Component, Input } from '@angular/core';
import { DialogsService } from '@common/dialogs/dialogs.service';
import { RfiResponseDialogComponent } from '@common/dialogs/rfi-response-dialog/rfi-response-dialog.component';
import { OrgMinModel, OrgModel } from '@main/org/models/org.model';
import { OrgFunction } from '@common/models/org-type.model';

@Component({
  selector: 'app-org-card',
  templateUrl: './org-card.component.html',
  styleUrls: ['./org-card.component.scss']
})
export class OrgCardComponent {
  @Input() viewingOrgId: number;
  @Input() orgInfo: OrgMinModel | OrgModel;
  @Input() orgFunction: OrgFunction;
  @Input() incumbent: boolean = false;
  @Input() showProfileLink: boolean = true;

  constructor(private dialogService: DialogsService) {}

  get viewProfileLabel() {
    return this.orgFunction === OrgFunction.PBM ? 'RxMarket IQ' : 'View Profile';
  }

  displayRfiProfile(): void {
    this.dialogService.openDialog<RfiResponseDialogComponent>(
      RfiResponseDialogComponent,
      {
        callingOrgId: this.viewingOrgId,
        requestedOrg: this.orgInfo,
        readOnly: true
      },
      {
        height: '100%'
      },
      '60vw'
    );
  }
}
