import { CurrencyPipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { Memoize } from '@app/tools/decorators/memoize.decorator';
import { BaseMultiFieldComponent } from '../base-multi-field/base-multi-field.component';

@Component({
  selector: 'app-multi-currency-field',
  template: `
    <label [class]="{ 'inline-label': inline, required: required }">
      <span
        class="label-text"
        [class]="!control.disabled && dataDef?.labelClasses ? dataDef.labelClasses : ''"
        truncateText
        [apply]="truncate"
        *ngIf="inline"
      >
        @if (dataDef.icon) {
          <fa-icon class="pr-1" [icon]="dataDef.icon"></fa-icon>
        }
        {{ label }}</span
      >
      <mat-form-field [appearance]="appearance" floatLabel="always">
        <mat-label *ngIf="!(inline || inlineNoLabel)" class="large-label"> {{ label }} </mat-label>
        <mat-chip-grid [required]="required" #chipList>
          <mat-chip-row
            *ngFor="let obj of control.value"
            [removable]="control.enabled"
            (removed)="remove(obj)"
            [disabled]="control.disabled"
          >
            {{ obj.label ? obj.label : formatCurrency(obj) }}
            <mat-icon matChipRemove *ngIf="control.enabled">cancel</mat-icon>
          </mat-chip-row>
          <input
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)"
            [disabled]="control.disabled"
            [(ngModel)]="value"
          />
        </mat-chip-grid>
        <mat-hint>
          @if (control.errors) {
            <mat-error>
              <app-error-renderer [errors]="control.errors"></app-error-renderer>
            </mat-error>
          } @else if (!control.errors && (!control.parent?.disabled || !control.disabled)) {
            <div truncateText>{{ hint ? hint : hintText }}</div>
          }
        </mat-hint>
      </mat-form-field>
    </label>
  `,
  styleUrls: ['../../form-lib.scss']
})
export class MultiCurrencyFieldComponent extends BaseMultiFieldComponent {
  value;
  constructor(private currencyPipe: CurrencyPipe) {
    super();
  }

  add(event: MatChipInputEvent): void {
    if (this.value === null || isNaN(this.value)) return;
    super.add(
      {
        input: event.chipInput.inputElement,
        value: this.value.toString(),
        chipInput: event.chipInput
      },
      true
    );
    this.value = null;
  }

  valueFormatter(value) {
    return (+super.valueFormatter(value)).toFixed(this.dataDef.dollarsOnly ? 0 : 2);
  }

  @Memoize()
  formatCurrency(value: number) {
    return isNaN(value)
      ? value
      : this.currencyPipe.transform(value, 'USD', 'symbol', this.dataDef.dollarsOnly ? '1.0-0' : '1.2-2');
  }
}
