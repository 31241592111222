export enum RxChannelIndicator {
  MAIL = 'MAIL',
  RETAIL = 'RETAIL'
}

export enum RxDrugType {
  BRAND = 'BRAND',
  GENERIC = 'GENERIC'
}

export enum RxClaimType {
  LIMITED_DIST_BRAND = 'LIMITED_DIST_BRAND',
  LIMITED_DIST_GENERIC = 'LIMITED_DIST_GENERIC',
  MAIL_BRAND = 'MAIL_BRAND',
  MAIL_GENERIC = 'MAIL_GENERIC',
  RETAIL30_BRAND = 'RETAIL30_BRAND',
  RETAIL30_GENERIC = 'RETAIL30_GENERIC',
  RETAIL90_BRAND = 'RETAIL90_BRAND',
  RETAIL90_GENERIC = 'RETAIL90_GENERIC',
  SPECIALTY_RETAIL_BRAND = 'SPECIALTY_RETAIL_BRAND',
  SPECIALTY_RETAIL_GENERIC = 'SPECIALTY_RETAIL_GENERIC',
  SPECIALTY_MAIL_BRAND = 'SPECIALTY_MAIL_BRAND',
  SPECIALTY_MAIL_GENERIC = 'SPECIALTY_MAIL_GENERIC'
}

export enum RxDaw {
  DAW0 = '0',
  DAW1 = '1',
  DAW2 = '2',
  DAW3 = '3',
  DAW4 = '4',
  DAW5 = '5',
  DAW6 = '6',
  DAW7 = '7',
  DAW8 = '8',
  DAW9 = '9'
}

export class RxClaimModel {
  id?: number;
  quoteRequestId?: number;
  code?: number;
  daySupply?: number;
  quantityFilled?: number;
  fillDate?: string;
  ingredientCost?: number;
  memberId?: string;
  channelIndicator?: RxChannelIndicator;
  nabp?: string;
  ncpdpId?: string;
  npi?: string;
  drugType?: RxDrugType;
  ucAmount?: number;
  compoundIndicator?: boolean;
  formularyIndicator?: boolean;
  formularyTier?: string;
  inhouseIndicator?: boolean;
  medispanAwp?: number;
  nadacAwp?: number;
  otc?: string;
  multiSourceCode?: string;
  nameTypeCode?: string;
  tcgpiId?: string;
  reversal?: boolean;
  invalidPriceHistory?: boolean;
  vaccine?: boolean;
  nonDiabeticDevicesAndSupplies?: boolean;
  diabeticMonitoringDevicesAndSupplies?: boolean;
  insulinPumps?: boolean;
  insulinSyringesAndNeedles?: boolean;
  teststrip?: boolean;
  hiv?: boolean;
  otcInsulin?: boolean;
  otcNonDiabetic?: boolean;
  brand?: boolean;
  multiSourceBrand?: boolean;
  type?: RxClaimType;
  discounted?: boolean;
  specialty?: boolean;
  limitedDist?: boolean;
  drugName?: string;
  gpi?: string;
  longTermCare?: boolean;
  va?: boolean;
  military?: boolean;
  homeInfusion?: boolean;
  ihs?: boolean;
  pcsk9?: boolean;
  dispensingfee?: number;
  indicator340b?: boolean;
  cobIndicator?: boolean;
  dmrIndicator?: boolean;
  daw?: number;
  ucIndicator?: boolean;
  memberCost?: number;
  covidAntiviral?: boolean;
  covidTest?: boolean;
  desiccatedThyroidExtract?: boolean;
  pharmacyName?: boolean;
  rmtTCName?: string;
}
