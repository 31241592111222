import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from '@app/app.constants';
import { HttpClient } from '@angular/common/http';
import { RfiFieldDataModel, RfiFieldModel, RfiTemplateModel } from '@common/models/rfi-template.model';
import { FormFieldType } from '@common/models/quote-type.model';
import { RfiFieldUIDataModel } from '@common/components/rfi-response/rfi-response.definitions';
import { RfiResponseModel } from '@common/models/rfi-response.model';
import { isArray } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root'
})
export class RfiService {
  constructor(private http: HttpClient) {}

  public getAllRfiTemplates(): Observable<RfiTemplateModel[]> {
    return this.http.get<RfiTemplateModel[]>(`${SERVER_API_URL}/rfi/all`);
  }

  public getRfiTemplate(id: number): Observable<RfiTemplateModel> {
    return this.http.get<RfiTemplateModel>(`${SERVER_API_URL}/rfi/${id}`);
  }

  public getRfiTemplateByOrgType(id: number): Observable<RfiTemplateModel> {
    return this.http.get<RfiTemplateModel>(`${SERVER_API_URL}/rfi/org-type/${id}`);
  }

  public getRfiTemplateByOrg(orgId: number): Observable<RfiTemplateModel> {
    return this.http.get<RfiTemplateModel>(`${SERVER_API_URL}/rfi/org/${orgId}`);
  }

  public updateRfiTemplate(template: RfiTemplateModel): Observable<RfiTemplateModel> {
    return this.http.put<RfiTemplateModel>(`${SERVER_API_URL}/rfi/${template.id}`, template);
  }

  public updateRfiResponse(orgId: number, rfiFieldData: RfiFieldDataModel[]): Observable<RfiResponseModel> {
    return this.http.put<RfiResponseModel>(`${SERVER_API_URL}/rfi/org/${orgId}/response`, rfiFieldData);
  }

  public getRfiResponseForRequestedOrgs(orgId: number, requestedOrgIds: number[]): Observable<RfiResponseModel[]> {
    return this.http.get<RfiResponseModel[]>(`${SERVER_API_URL}/rfi/org/${orgId}/requested-org/responses`, {
      params: { requestedOrgIds: requestedOrgIds }
    });
  }

  public buildPreviewUIData(rfiFields: RfiFieldModel[]): RfiFieldUIDataModel[] {
    return rfiFields
      .sort((a, b) => a.formFieldDefinition.orderIndex - b.formFieldDefinition.orderIndex)
      .map((rfiField) => ({
        rfiField: rfiField,
        value: undefined
      }));
  }

  public mapUIDataToServerData(rfiFieldDataUI: RfiFieldUIDataModel[], orgId: number): RfiFieldDataModel[] {
    return rfiFieldDataUI
      .sort((a, b) => a.rfiField.formFieldDefinition.orderIndex - b.rfiField.formFieldDefinition.orderIndex)
      .map((uiData) => ({
        id: uiData.id,
        orgId: orgId,
        rfiField: uiData.rfiField,
        values:
          uiData.rfiField.formFieldDefinition.type !== FormFieldType.BOOLEAN
            ? isArray(uiData.value)
              ? uiData?.value
              : [uiData?.value].filter((o) => o !== null)
            : uiData?.value
              ? ['true']
              : ['false']
      }));
  }

  public mapServerFieldDataToUIData(fieldData: RfiFieldDataModel): RfiFieldUIDataModel {
    return {
      ...fieldData,
      value:
        fieldData.rfiField.formFieldDefinition.type !== FormFieldType.BOOLEAN
          ? fieldData.rfiField.formFieldDefinition.inputSize > 1 &&
            fieldData.rfiField.formFieldDefinition.type !== FormFieldType.TEXT
            ? fieldData.values
            : fieldData.values[0]
          : fieldData.values[0] === 'true'
            ? true
            : fieldData.values[0] === 'false'
              ? false
              : undefined
    };
  }

  public consolidateFieldsWithData(
    rfiFields: RfiFieldModel[],
    rfiFieldData: RfiFieldDataModel[]
  ): RfiFieldUIDataModel[] {
    rfiFields.sort((a, b) => a.formFieldDefinition.orderIndex - b.formFieldDefinition.orderIndex);
    return rfiFields.map((rfiField) => {
      const matchingData = rfiFieldData.find((data) => data.rfiField.id === rfiField.id);
      return !!matchingData
        ? this.mapServerFieldDataToUIData(matchingData)
        : {
            rfiField: rfiField,
            value: undefined
          };
    });
  }
}
