import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RfiService } from '@common/services/rfi.service';
import { Observable } from 'rxjs';
import { RfiFieldModel, RfiTemplateModel } from '@common/models/rfi-template.model';
import { finalize, tap } from 'rxjs/operators';
import { RfiQuestionSelection, RfiSection } from '@common/dialogs/compare-pbms-dialog/compare-pbms-dialog.definitions';
import { FormFieldType } from '@common/models/quote-type.model';

@Component({
  selector: 'app-select-rfi-questions',
  templateUrl: './select-rfi-questions.component.html',
  styleUrls: ['./select-rfi-questions.component.scss']
})
export class SelectRfiQuestionsComponent implements OnInit {
  @Input() orgTypeId: number;
  @Input() currentSelection: RfiQuestionSelection;
  @Output() rfiQuestionsSelected = new EventEmitter<RfiQuestionSelection>();

  constructor(private rfiService: RfiService) {}

  rfiTemplate$: Observable<RfiTemplateModel>;
  rfiSections: RfiSection[];
  selectedRfiQuestions: Set<number> = new Set();
  allQuestions: RfiFieldModel[] = [];
  includeCompanyOverview: boolean = true;
  loading: boolean = false;

  ngOnInit(): void {
    this.loading = true;
    this.rfiTemplate$ = this.rfiService.getRfiTemplateByOrgType(this.orgTypeId).pipe(
      tap((rfiTemplate) => {
        this.rfiSections = this.buildSections(rfiTemplate.rfiFields);

        if (!!this.currentSelection) {
          this.includeCompanyOverview = this.currentSelection.includeCompanyOverview;
          this.currentSelection.selectedRfiQuestions.forEach((selectedQuestion) =>
            this.selectedRfiQuestions.add(selectedQuestion.id)
          );
        } else {
          // Select All
          this.rfiSections.forEach((section) => this.selectSection({ checked: true }, section));
        }
        this.updateQuestionSelection();
      }),
      finalize(() => (this.loading = false))
    );
  }

  buildSections(toBuild: RfiFieldModel[]): RfiSection[] {
    const sections: RfiSection[] = [];
    if (!toBuild?.length) {
      return sections;
    }

    const rfiFields: RfiFieldModel[] = toBuild.sort(
      (a, b) => a.formFieldDefinition.orderIndex - b.formFieldDefinition.orderIndex
    );

    const firstIsSection = rfiFields[0].formFieldDefinition.type === FormFieldType.SECTION_HEADER;
    let currentSection: RfiSection = {
      section: firstIsSection ? rfiFields[0] : undefined,
      fields: []
    };

    this.allQuestions.push(rfiFields[0]);

    for (let i = firstIsSection ? 1 : 0; i < rfiFields.length; i++) {
      this.allQuestions.push(rfiFields[i]);
      if (rfiFields[i].formFieldDefinition.type === FormFieldType.SECTION_HEADER) {
        sections.push({ ...currentSection });
        currentSection = {
          section: rfiFields[i],
          fields: []
        };
      } else {
        currentSection.fields.push(rfiFields[i]);
      }
    }

    if (!sections.length || sections[sections.length - 1].section?.id !== currentSection.section?.id) {
      sections.push(currentSection);
    }

    // Filter out empty sections
    return sections.filter((section) => !!section.fields.length);
  }

  selectCompanyOverview(event) {
    this.includeCompanyOverview = event.checked;
    this.updateQuestionSelection();
  }

  companyOverviewSelected(): boolean {
    return this.includeCompanyOverview;
  }

  selectSection(event, section: RfiSection) {
    if (event.checked) {
      this.selectedRfiQuestions.add(section.section.id);
      [...this.selectedRfiQuestions, ...section.fields.map((field) => field.id)].forEach((questionId) =>
        this.selectedRfiQuestions.add(questionId)
      );
    } else {
      [section.section, ...section.fields].map((toRemove) => this.selectedRfiQuestions.delete(toRemove.id));
    }

    this.updateQuestionSelection();
  }

  sectionSelected(section: RfiSection): boolean {
    let sectionSelected = this.selectedRfiQuestions.has(section.section.id);
    return sectionSelected || this.sectionHasAnyQuestionsSelected(section);
  }

  selectQuestion(event, section: RfiSection, question: RfiFieldModel) {
    if (event.checked) {
      this.selectedRfiQuestions.add(question.id);
      this.selectedRfiQuestions.add(section.section.id);
    } else {
      this.selectedRfiQuestions.delete(question.id);
      if (!this.sectionHasAnyQuestionsSelected(section)) {
        this.selectedRfiQuestions.delete(section.section.id);
      }
    }

    this.updateQuestionSelection();
  }

  questionSelected(question: RfiFieldModel): boolean {
    return this.selectedRfiQuestions.has(question.id);
  }

  sectionHasAnyQuestionsSelected(section: RfiSection): boolean {
    let anyQuestionsSelected = false;
    section.fields.forEach(
      (toCheck) => (anyQuestionsSelected = anyQuestionsSelected || this.selectedRfiQuestions.has(toCheck.id))
    );
    return anyQuestionsSelected;
  }

  updateQuestionSelection() {
    this.rfiQuestionsSelected.emit({
      includeCompanyOverview: this.includeCompanyOverview,
      selectedRfiQuestions: this.allQuestions.filter((question) => this.selectedRfiQuestions.has(question.id))
    });
  }
}
