@if (loading) {
  <div class="compare-pbms-loading">
    <app-in-progress-indicator diameter="1.25rem"></app-in-progress-indicator>
  </div>
}
@if (rfiTemplate$ | async) {
  <div class="rfi-question-picker-container">
    <span>Select sections and questions to continue.</span>
    <!-- Company Overview -->
    <div class="rfi-question-section">
      <mat-checkbox
        (change)="selectCompanyOverview($event)"
        [checked]="companyOverviewSelected()"
        class="ml-2 pbm-checkbox"
        color="primary"
      >
        <span class="rfi-question-section-header">Company Overview</span>
      </mat-checkbox>
    </div>
    @for (section of rfiSections; track section) {
      <div class="rfi-question-section">
        <!-- Section itself -->
        <div class="rfi-question-select">
          <mat-checkbox
            (change)="selectSection($event, section)"
            [checked]="sectionSelected(section)"
            class="ml-2 pbm-checkbox"
            color="primary"
          >
            <span class="rfi-question-section-header">{{ section.section?.formFieldDefinition?.name }}</span>
          </mat-checkbox>
        </div>
        @for (field of section.fields; track field) {
          <div class="rfi-question-select">
            <mat-checkbox
              (change)="selectQuestion($event, section, field)"
              [checked]="questionSelected(field)"
              class="ml-4 pbm-checkbox"
              color="primary"
            >
              {{ field.formFieldDefinition.name }}
            </mat-checkbox>
          </div>
        }
      </div>
    }
  </div>
}
