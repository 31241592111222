import { Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { LabelValue } from '@lib-resource/label-value.model';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, pairwise, startWith, take } from 'rxjs/operators';
import { AsyncOptions } from '../../options/options.model';
import { OptionsService } from '../../options/options.service';
import { BaseFieldComponent } from '../base-field/base-field.component';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseSelectFieldComponent extends BaseFieldComponent implements OnChanges, OnInit, OnDestroy {
  addOnBlur = true;
  asyncOptionsService: AsyncOptions<any>;
  selectedOptions: LabelValue[] = []; // Should represent the entire LabelValue object in order to display label
  pageIndex = 0;
  pageSize = 40;
  searching = false;
  total = 0;
  filterValue = '';
  currentOptionsSource = new BehaviorSubject([]);
  currentOptions$ = this.currentOptionsSource.asObservable();
  subs = new Subscription();

  @Input() requiredQuery: string;

  protected constructor(protected optionsService: OptionsService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.requiredQuery && !changes.requiredQuery.firstChange) {
      this.getAsyncOptions();
    }
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.asyncOptions) {
      this.asyncOptionsService = this.optionsService.getAsyncOptionsService(this.asyncOptions);
      if (!this.asyncOptionsService) {
        console.warn(`No async option service found for field [${this.label}] with key of [${this.asyncOptions}]`);
        return;
      }
      if (!this.control.value || (this.isMulti && this.control.value.length === 0)) return;
      const values = this.isMulti ? this.control.value : [this.control.value];
      if (this.isMulti) {
        this.asyncOptionsService
          .valuesFromKeys(
            values,
            this.isMulti,
            this.derivedAsyncExtras
              ? this.derivedAsyncExtras(this.control.parent.controls, this.dataDef)
              : this.asyncExtras
          )
          .pipe(take(1))
          .subscribe((result) => (this.selectedOptions = result));
      }
    }
  }

  abstract _filter(_): any;
  abstract updateOptions(_): any;

  getAsyncOptions() {
    this.subs.add(
      this.asyncOptionsService
        .filter(
          this.control,
          this.asyncOptionsDeps,
          this.filterValue,
          this.pageIndex,
          this.pageSize,
          null,
          this.requiredQuery,
          this.derivedAsyncExtras
            ? this.derivedAsyncExtras(this.control.parent.controls, this.dataDef)
            : this.asyncExtras
        )
        .subscribe(({ content, total }) => {
          this.updateOptions(content);
          this.total = total;
          this.searching = false;
        })
    );
  }

  openedChange() {
    this.control.setValue(this.selectedOptions.map((item) => item.value));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  protected asyncDependentSelectFieldSetup() {
    // watch for changes to dependent parent controls.
    // when there are changes to the dependent parent controls, then invoke this control's getAsyncOptions().
    const allParentControlStartValues = [];
    if (!!this.asyncOptionsDeps && this.asyncOptionsDeps.length > 0) {
      const asyncOptionsBeingWatched = [];
      this.asyncOptionsDeps.forEach((dep) => {
        const parentControlStartsWith = this.control.parent.controls[dep]?.value;
        if (!!parentControlStartsWith) {
          allParentControlStartValues.push(parentControlStartsWith);
        }
        const parentControl = this.control.parent.controls[dep];
        if (!!parentControl) {
          asyncOptionsBeingWatched.push(parentControl.valueChanges.pipe(startWith(parentControlStartsWith)));
        }
      });
      this.subs.add(
        combineLatest(asyncOptionsBeingWatched)
          .pipe(debounceTime(this.dataDef.asyncImmediate ? 0 : 500), startWith(allParentControlStartValues), pairwise())
          .subscribe(([prev, next]) => {
            if (JSON.stringify(prev) !== JSON.stringify(next)) {
              // reset the selections, since there is a change in an option group higher in the hierarchy
              // but only if the control is currently enabled
              // leaning on the fact that when the control is going back to disabled
              // that the cancel action and discard changes occurred and therefore the form already changed this value back to the original
              // and by clearing it we lose the value
              if (this.control.enabled) {
                this.resetToNoSelectedOptions();
              }
              this.getAsyncOptions();
            }
          })
      );
    }
  }

  protected resetToNoSelectedOptions() {}
}
