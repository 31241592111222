import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DiscoveredOrgModel, FeatureType } from '@main/org/models/org.model';
import {
  orgOptions,
  PbmSelection,
  RfiQuestionSelection,
  sysAdminOrgOptions
} from '@common/dialogs/compare-pbms-dialog/compare-pbms-dialog.definitions';
import { OrgTypeService } from '@common/services/org-type.service';
import { combineLatest, EMPTY, Observable, Subscription } from 'rxjs';
import { expand, filter, finalize, map, reduce } from 'rxjs/operators';
import { DataDefModel } from '@lib-resource/data-def.model';
import { SiteFilterSelectors } from '@main/store/site-filter/site-filter.selectors';
import { UserSelectors } from '@main/store/user/user.selectors';
import { Store } from '@ngxs/store';
import { OrgHttpService } from '@main/org/org-http.service';
import { Page } from '@common/models/page.model';

enum RfiTab {
  SELECT_ORG,
  SELECT_PBMS,
  SELECT_QUESTIONS,
  VIEW_PBM_PROFILES
}

@Component({
  templateUrl: './compare-pbms-dialog.component.html',
  styleUrls: ['./compare-pbms-dialog.component.scss']
})
export class ComparePbmsDialogComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { brokerId: number },
    private orgTypeService: OrgTypeService,
    private store: Store,
    private orgService: OrgHttpService
  ) {}

  selectedOrgId: number;
  pbmSelection: PbmSelection;
  rfiQuestionSelection: RfiQuestionSelection;
  currentTab: RfiTab = RfiTab.SELECT_ORG;
  orgTypeId$: Observable<number>;
  loading: boolean = false;
  subs: Subscription = new Subscription();

  ngOnInit(): void {
    // Move on if there was already an org selected otherwise we will make them pick an org
    if (this.data.brokerId) {
      this.selectedOrgId = this.data.brokerId;
      this.currentTab = RfiTab.SELECT_PBMS;
    }

    this.orgTypeId$ = this.orgTypeService.getOrgTypes(`name = 'PBM'`).pipe(
      filter((orgType) => !!orgType),
      map((orgType) => orgType.content[0]?.id)
    );
  }

  get canBack(): boolean {
    return this.currentTab !== RfiTab.SELECT_ORG && (this.currentTab !== RfiTab.SELECT_PBMS || !this.selectedOrgId);
  }

  get canNext(): boolean {
    switch (this.currentTab) {
      case RfiTab.SELECT_ORG:
        return !!this.selectedOrgId;
      case RfiTab.SELECT_PBMS:
        return this.pbmSelection?.allSelected || !!this.pbmSelection?.selectedPbms?.length;
      case RfiTab.SELECT_QUESTIONS:
        return (
          !!this.rfiQuestionSelection?.selectedRfiQuestions?.length || this.rfiQuestionSelection?.includeCompanyOverview
        );
      case RfiTab.VIEW_PBM_PROFILES:
        return false;
      default:
        return false;
    }
  }

  next() {
    if (this.canNext) {
      if (this.currentTab === RfiTab.SELECT_PBMS && this.pbmSelection.allSelected && !this.pbmSelection.allFetched) {
        let page = 0;
        let totalFetched = 0;
        this.loading = true;
        this.subs.add(
          this.orgService
            .discoverPBMsForOrg(this.selectedOrgId, page, 1000)
            .pipe(
              expand((response: Page<DiscoveredOrgModel>) => {
                totalFetched += response.content.length;
                return totalFetched < response.total
                  ? this.orgService.discoverPBMsForOrg(this.selectedOrgId, page, 1000)
                  : EMPTY;
              }),
              reduce((acc: DiscoveredOrgModel[], current) => acc.concat(...current.content), []),
              finalize(() => (this.loading = false))
            )
            .subscribe((allDiscoveredPbmsRespnse) => {
              this.pbmSelection.selectedPbms = allDiscoveredPbmsRespnse.map((pbm, index) => ({
                index: index,
                pbm: pbm
              }));
              this.pbmSelection.allFetched = true;
            })
        );
      }

      this.currentTab++;
    }
  }

  back() {
    if (this.canBack) {
      this.currentTab--;
    }
  }

  // show all orgs for the selected org function if a sysadmin, otherwise, orgs the user has access to in this product
  definitions$: Observable<DataDefModel[]> = combineLatest([
    this.store.select(SiteFilterSelectors.orgFunction),
    this.store.select(UserSelectors.isSysAdmin)
  ]).pipe(
    map((res) =>
      res[1]
        ? [
            {
              ...sysAdminOrgOptions,
              asyncExtras: {
                filter: `features ~ ('${FeatureType.quoteLinqRxEnabled}') and orgtype.orgfunction = '${res[0]}'`
              }
            }
          ]
        : [
            {
              ...orgOptions,
              asyncExtras: {
                filter: `orgfeatures ~ ('${FeatureType.quoteLinqRxEnabled}') and orgfunction = '${res[0]}'`
              }
            }
          ]
    )
  );

  changeOrgSelect(selectedOrg) {
    if (selectedOrg?.orgId > 0) {
      this.selectedOrgId = selectedOrg.orgId;
      this.next();
    }
  }

  updateSelectedPbms(pbmSelectUpdate: PbmSelection) {
    this.pbmSelection = pbmSelectUpdate;
  }

  updateSelectedRfiQuestions(rfiSelectUpdate: RfiQuestionSelection) {
    this.rfiQuestionSelection = rfiSelectUpdate;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  protected readonly RfiTab = RfiTab;
}
