<app-dialog-close icon mat-dialog-close></app-dialog-close>
<app-dialog-label> RxMarket IQ Comparison </app-dialog-label>

<app-dialog-content>
  @if (loading) {
    <div class="compare-pbms-loading">
      <app-in-progress-indicator diameter="1.25rem"></app-in-progress-indicator>
    </div>
  } @else {
    <mat-tab-group class="no-tab-header" style="height: 100%" [dynamicHeight]="false" [(selectedIndex)]="currentTab">
      <!-- SELECT_ORG -->
      <mat-tab>
        <ng-template matTabContent>
          @if (currentTab === RfiTab.SELECT_ORG) {
            <app-simple-form
              class="compare-pbms-select-org"
              inlineLabels="true"
              [editing]="true"
              (doSubmit)="changeOrgSelect($event)"
              [definitions]="definitions$ | async"
              (valueChanges)="changeOrgSelect($event)"
            ></app-simple-form>
          }
        </ng-template>
      </mat-tab>

      <!-- SELECT_PBMS -->
      <mat-tab>
        <ng-template matTabContent>
          @if (!!selectedOrgId && currentTab === RfiTab.SELECT_PBMS) {
            <app-select-pbms [brokerId]="selectedOrgId" (pbmSelection)="updateSelectedPbms($event)"> </app-select-pbms>
          }
        </ng-template>
      </mat-tab>

      <!-- SELECT_QUESTIONS -->
      <mat-tab>
        <ng-template matTabContent>
          @if (!!selectedOrgId && orgTypeId$ | async; as orgTypeId) {
            <app-select-rfi-questions
              class="compare-pbms-question-list"
              [orgTypeId]="orgTypeId"
              [currentSelection]="rfiQuestionSelection"
              (rfiQuestionsSelected)="updateSelectedRfiQuestions($event)"
            >
            </app-select-rfi-questions>
          }
        </ng-template>
      </mat-tab>

      <!-- VIEW_PBM_PROFILES -->
      <mat-tab>
        <ng-template matTabContent>
          @if (!!selectedOrgId && !!pbmSelection && currentTab === RfiTab.VIEW_PBM_PROFILES) {
            <app-compare-pbms
              [brokerId]="selectedOrgId"
              [pbmsToCompare]="pbmSelection.selectedPbms"
              [includeCompanyOverview]="rfiQuestionSelection.includeCompanyOverview"
              [rfiQuestionsToShow]="rfiQuestionSelection.selectedRfiQuestions"
            ></app-compare-pbms>
          }
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  }
</app-dialog-content>
<app-dialog-actions>
  <button mat-raised-button secondaryActions [disabled]="!canBack" (click)="back()">Back</button>
  @if (currentTab !== RfiTab.VIEW_PBM_PROFILES) {
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" [disabled]="!canNext" (click)="next()">Next</button>
  }
  @if (currentTab === RfiTab.VIEW_PBM_PROFILES) {
    <button mat-raised-button mat-dialog-close>Close</button>
  }
</app-dialog-actions>
