import { FileModel } from '@file-upload-lib/file.model';

export enum UserJobType {
  RXAUDITCLAIM_BULK_DELETE = 'RXAUDITCLAIM_BULK_DELETE',
  RXAUDITCLAIM_BULK_RECALC = 'RXAUDITCLAIM_BULK_RECALC',
  QUOTEREQUEST_RX_MANIFEST_DOWNLOAD = 'QUOTEREQUEST_RX_MANIFEST_DOWNLOAD',
  RXAUDITCLAIM_IMPORT = 'RXAUDITCLAIM_IMPORT',
  RXAUDITCLAIM_STAT = 'RXAUDITCLAIM_STAT'
}

export enum UserJobStatus {
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  IN_PROCESS = 'IN_PROCESS'
}

export class UserJobModel {
  public id?: number;
  public type?: UserJobType;
  public status?: UserJobStatus;
  public createdDate?: string;
  public message?: string;
  public description?: string;
  public rmtFile?: FileModel;
}
