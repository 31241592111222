import { Component, Input, OnInit } from '@angular/core';
import { RfiResponseModel } from '@common/models/rfi-response.model';
import { RfiFieldDataModel, RfiFieldModel } from '@common/models/rfi-template.model';
import { Memoize } from '@app/tools/decorators/memoize.decorator';
import { DataDefModel } from '@lib-resource/data-def.model';
import { buildDataDefFromFieldDef } from '@common/components/form-builder/form-field-data.utils';
import { FormFieldDefinitionModel } from '@common/models/form-field-definition.model';
import { RfiService } from '@common/services/rfi.service';
import { RfiFieldUIDataModel } from '@common/components/rfi-response/rfi-response.definitions';
import { FormFieldType } from '@common/models/quote-type.model';
import { PopoverService } from '@shared/components/popover/popover.service';
import { DataFormatterPipe } from '@lib-resource/pipes/data-formatter.pipe';

@Component({
  selector: 'app-rfi-vertical-panel',
  templateUrl: 'rfi-vertical-panel.component.html',
  styleUrls: ['./rfi-vertical-panel.component.scss'],
  providers: [DataFormatterPipe]
})
export class RfiVerticalPanelComponent implements OnInit {
  @Input() includeCompanyOverview: boolean;
  @Input() rfiResponse: RfiResponseModel;
  @Input() rfiQuestionsToShow: RfiFieldModel[];

  constructor(
    private rfiService: RfiService,
    private popoverService: PopoverService,
    private dataFormatterPipe: DataFormatterPipe
  ) {}

  rfiAnswersToShow: RfiFieldDataModel[];
  loading: boolean = false;

  ngOnInit(): void {
    this.rfiAnswersToShow = this.rfiQuestionsToShow.map((fieldToShow) => {
      const answerToShow = this.rfiResponse.rfiFieldData.find((fieldData) => fieldData.rfiField.id === fieldToShow.id);
      return !!answerToShow ? answerToShow : { values: ['--'], rfiField: fieldToShow };
    });
  }

  @Memoize()
  getDataDefForAnswer(answerDef: FormFieldDefinitionModel): DataDefModel {
    return buildDataDefFromFieldDef(answerDef, false);
  }

  mapAnswerToValue(answer: RfiFieldDataModel): RfiFieldUIDataModel {
    return this.rfiService.mapServerFieldDataToUIData(answer);
  }

  emptyDefault(value: string): string {
    return !!value ? value : '--';
  }

  isTextArea(def: FormFieldDefinitionModel): boolean {
    return def.type === FormFieldType.TEXT && def.inputSize > 1;
  }

  openPopover(target: any, answer: RfiFieldDataModel) {
    if (this.isTextArea(answer.rfiField.formFieldDefinition)) {
      this.popoverService.openMessage(
        target,
        {
          label: answer.rfiField.formFieldDefinition.name,
          message: this.dataFormatterPipe.transform(
            this.mapAnswerToValue(answer),
            this.getDataDefForAnswer(answer.rfiField.formFieldDefinition),
            '--'
          )
        },
        { panelClass: 'kpi-popover-dialog', targetOffsetX: 0, targetOffsetY: 0 }
      );
    }
  }

  openOverviewPopover(target: any, label: string, body: string) {
    this.popoverService.openMessage(
      target,
      {
        label: label,
        message: body
      },
      { panelClass: 'kpi-popover-dialog', targetOffsetX: 0, targetOffsetY: 0 }
    );
  }
}
