import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserJobService } from '@shared/components/user-job-progress/user-job.service';
import { faCircleNotch, faInfoCircle, faWarning, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { UserJobModel, UserJobStatus, UserJobType } from '@common/models/user-job.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Page } from '@common/models/page.model';
import { Memoize } from '@app/tools/decorators/memoize.decorator';
import { take } from 'rxjs/operators';
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'app-user-job-progress-banner',
  templateUrl: 'user-job-progress-banner.component.html',
  styleUrls: ['user-job-progress-banner.component.scss']
})
export class UserJobProgressBanner implements OnInit, OnDestroy {
  protected readonly UserJobStatus = UserJobStatus;
  @Input()
  type: UserJobType;

  autoRefresh: Timeout;
  warnIcon = faWarning;
  closeIcon = faXmark;
  loadingIcon = faCircleNotch;

  userJobsSubject = new BehaviorSubject<Page<UserJobModel>>({ total: 0 });
  userJobs$: Observable<Page<UserJobModel>> = this.userJobsSubject.asObservable();

  constructor(private userJobService: UserJobService) {}

  ngOnInit(): void {
    this.loadJobs();
  }

  ngOnDestroy(): void {
    this.cancelAutoRefresh();
  }

  cancelAutoRefresh() {
    if (!!this.autoRefresh) {
      clearTimeout(this.autoRefresh);
    }
  }

  startAutoRefresh(time: number) {
    this.cancelAutoRefresh();
    this.autoRefresh = setTimeout((_) => this.loadJobs(), time) as unknown as Timeout;
  }

  loadJobs() {
    this.userJobService
      .search(`type = '${this.type}'`, 0, 5)
      .pipe(take(1))
      .subscribe((res) => {
        this.userJobsSubject.next(res);
        if (res.content.find((userJob) => userJob.status === UserJobStatus.IN_PROCESS)) {
          // at least one is in process, just refresh the jobs every so often
          this.startAutoRefresh(5000);
        }
      });
  }

  clearUserJob(job: UserJobModel) {
    this.userJobService.delete(job.id).subscribe((res) => this.loadJobs());
  }
}
