<span>Select PBMs to continue.</span>
<div class="select-pbm-container">
  <div class="select-all-pbms">
    <mat-checkbox (change)="selectAll($event)" [checked]="allAreSelected()" class="ml-2" color="primary">
      Select All
    </mat-checkbox>
  </div>
  <cdk-virtual-scroll-viewport
    itemSize="130"
    appendOnly="true"
    class="select-pbm-scroll"
    (scrolledIndexChange)="handleScroll()"
  >
    <div *cdkVirtualFor="let pbm of pbmList; let i = index" class="select-pbm-card-container">
      <app-org-card class="org-card" [viewingOrgId]="brokerId" [orgInfo]="pbm" [orgFunction]="OrgFunction.PBM">
        <mat-checkbox
          checkbox
          (change)="selectPbm($event, pbm, i)"
          [checked]="pbmSelected(pbm)"
          class="ml-2 pbm-checkbox"
          color="primary"
        ></mat-checkbox>
      </app-org-card>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
