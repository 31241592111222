import { Injectable } from '@angular/core';
import { AsyncOptions } from '@form-lib/options/options.model';
import { OrgHttpService } from '@main/org/org-http.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

export const defaultSort = ['name'];

export function generateFilterString(value) {
  if (!value) return '';
  return `name ~ '${value}' or notes ~ '${value}'`;
}

@Injectable({
  providedIn: 'root'
})
export class OrgOptionService extends AsyncOptions<string> {
  key = 'orgs';

  constructor(private orgHttpService: OrgHttpService) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, _?, requiredQuery?, asyncExtras?) => {
    // asyncExtras may have a value for filter
    const filter = this.combineFilters(generateFilterString(value), asyncExtras?.filter);
    return this.orgHttpService
      .searchAllOrgs(this.combineFilters(filter, requiredQuery), pageIndex, pageSize, defaultSort)
      .pipe(map(({ content, total }) => ({ content: this.generateLabelValues(content), total })));
  };

  valuesFromKeys = (values: string[], multi) => {
    if (!values?.length) return of(null);
    const filter = multi ? `id ~ ('${values.join("','")}')` : `id ~ ('${values}')`;
    return this.orgHttpService
      .searchAllOrgs(filter, 0, 1000, defaultSort)
      .pipe(map((result) => this.generateLabelValues(result.content)));
  };
}
