import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Page } from '@app/common/models/page.model';
import { DiscoveredOrgModel } from '@main/org/models/org.model';
import { Subscription } from 'rxjs';
import { OrgHttpService } from '@main/org/org-http.service';
import { PbmSelection, SelectedPBM } from '@common/dialogs/compare-pbms-dialog/compare-pbms-dialog.definitions';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { OrgFunction } from '@common/models/org-type.model';

@Component({
  selector: 'app-select-pbms',
  templateUrl: './select-pbms.component.html',
  styleUrls: ['./select-pbms.component.scss']
})
export class SelectPbmsComponent implements OnInit, OnDestroy {
  @Input() brokerId: number;
  @Output() pbmSelection = new EventEmitter<PbmSelection>();
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  viewport: CdkVirtualScrollViewport;

  currentPage: number = 0;
  pageSize: number = 10;
  loading: boolean = false;
  allFetched: boolean = false;
  totalPbms: number;
  pbmList: DiscoveredOrgModel[] = [];
  subs: Subscription = new Subscription();
  selectedPbms: SelectedPBM[] = [];
  allSelected: boolean = false;

  protected readonly OrgFunction = OrgFunction;

  constructor(private orgService: OrgHttpService) {}

  ngOnInit(): void {
    this.loadMorePbms();
    this.updatePbmSelection();
  }

  loadMorePbms(): void {
    if (this.loading || this.allFetched) {
      return;
    }

    this.loading = true;

    this.subs.add(
      this.orgService
        .discoverPBMsForOrg(this.brokerId, this.currentPage, this.pageSize)
        .subscribe((pbmPage: Page<DiscoveredOrgModel>) => {
          this.pbmList = [...this.pbmList, ...pbmPage.content];
          this.currentPage++;
          this.loading = false;
          this.totalPbms = pbmPage.total;
          this.allFetched = this.pbmList.length === this.totalPbms;
        })
    );
  }

  allAreSelected(): boolean {
    return this.allSelected || (!!this.totalPbms && this.selectedPbms.length === this.totalPbms);
  }

  selectAll(event) {
    this.allSelected = event.checked;
    if (!this.allSelected) {
      this.selectedPbms = [];
    } else {
      this.selectedPbms = [];
      this.selectedPbms = this.pbmList.map((pbm, index) => ({ index: index, pbm: pbm }));
    }

    this.updatePbmSelection();
  }

  pbmSelected(pbm: DiscoveredOrgModel): boolean {
    return this.allSelected || this.selectedPbms.some((arrPbm) => arrPbm.pbm.id === pbm.id);
  }

  selectPbm(event, pbm: DiscoveredOrgModel, index: number) {
    if (event.checked) {
      this.selectedPbms.push({ index: index, pbm: pbm });
      this.allSelected = this.totalPbms === this.selectedPbms.length;
    } else {
      this.allSelected = false;
      this.selectedPbms = this.selectedPbms.filter((x) => x.pbm.id !== pbm.id);
    }

    this.updatePbmSelection();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  handleScroll() {
    if (this.pbmList.length === this.totalPbms) return;
    // When the number of items rendered is 2 away from the total loaded (so far), then load some more
    if (this.viewport.getRenderedRange().end + 2 >= this.pbmList.length) this.loadMorePbms();
  }

  updatePbmSelection() {
    this.pbmSelection.emit({
      allSelected: this.allSelected,
      allFetched: this.allFetched,
      selectedPbms: this.selectedPbms
    });
  }
}
