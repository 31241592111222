@if (userJobs$ | async; as userJobs) {
  @if (userJobs.total > 0) {
    <div class="user-job-progress-banner-container">
      @for (job of userJobs.content; track job) {
        <div class="user-job-progress-banner">
          <div class="warn-icon-conainer"><fa-icon [icon]="warnIcon" class="warn-icon"></fa-icon></div>
          <div class="user-job-body">
            <div class="user-job-title">
              {{ job.description }}
            </div>
            <div class="user-job-desc">{{ job.message }} (Started {{ job.createdDate | appDateTime }})</div>
          </div>
          <div class="user-job-status">
            @if (job.status === UserJobStatus.IN_PROCESS) {
              <fa-icon style="font-size: 1.5rem" [icon]="loadingIcon" [spin]="true"></fa-icon>
            } @else {
              <fa-icon [icon]="closeIcon" class="close-icon" (click)="clearUserJob(job)"></fa-icon>
            }
          </div>
        </div>
      }
      @if (userJobs.total > 5) {
        <div class="user-job-progress-banner">
          <div><fa-icon [icon]="warnIcon" class="warn-icon"></fa-icon></div>
          <div class="user-job-desc">There are {{ userJobs.total - 5 }} additional jobs available</div>
          <div></div>
        </div>
      }
    </div>
  }
}
