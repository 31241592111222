import { Validators } from '@angular/forms';
import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';
import { FormFieldType } from '@common/models/quote-type.model';
import { FormFieldDefinitionModel } from '@common/models/form-field-definition.model';
import { FieldValidators } from '@form-lib/validators/field.validators';

export function buildFieldDefinition(
  formGroup: any,
  fieldDef: FormFieldDefinitionModel,
  isComment: boolean = false
): DataDefModel {
  if (fieldDef.required) {
    formGroup.controls['value'].addValidators(Validators.required);
  }
  if (fieldDef.minInputSize !== undefined && fieldDef.minInputSize !== null) {
    if (fieldDef.type === FormFieldType.TEXT) {
      formGroup.controls['value'].addValidators(Validators.minLength(fieldDef.minInputSize));
    } else if (fieldDef.type !== FormFieldType.SELECT && fieldDef.inputSize > 1) {
      formGroup.controls['value'].addValidators(FieldValidators.minSize(fieldDef.minInputSize));
    } else if (fieldDef.type !== FormFieldType.SELECT) {
      formGroup.controls['value'].addValidators(Validators.min(fieldDef.minInputSize));
    }
  }
  if (fieldDef.maxInputSize !== undefined && fieldDef.maxInputSize !== null) {
    if (fieldDef.type === FormFieldType.TEXT) {
      formGroup.controls['value'].addValidators(Validators.maxLength(fieldDef.maxInputSize));
    } else if (fieldDef.type !== FormFieldType.SELECT && fieldDef.inputSize > 1) {
      formGroup.controls['value'].addValidators(FieldValidators.maxSize(fieldDef.maxInputSize));
    } else if (fieldDef.type !== FormFieldType.SELECT) {
      formGroup.controls['value'].addValidators(Validators.max(fieldDef.maxInputSize));
    }
  }

  return buildDataDefFromFieldDef(fieldDef, isComment);
}

export function buildDataDefFromFieldDef(fieldDef: FormFieldDefinitionModel, isComment: boolean): DataDefModel {
  let def: DataDefModel = new DataDefModel({
    key: isComment ? 'comment' : 'value',
    label: isComment ? 'Enter Comment' : fieldDef.name
  });

  if (fieldDef.dollarsOnly !== undefined && fieldDef.dollarsOnly !== null) {
    def.dollarsOnly = fieldDef.dollarsOnly;
  }

  switch (fieldDef.type) {
    case FormFieldType.BOOLEAN:
      def = {
        ...def,
        type: DATA_TYPES.boolean
      };
      break;
    case FormFieldType.TEXT:
      if (fieldDef.inputSize > 1) {
        def = {
          ...def,
          type: DATA_TYPES.textarea
        };
      } else {
        def = {
          ...def,
          type: DATA_TYPES.text
        };
      }
      break;
    case FormFieldType.YES_NO_COMMENTS:
      if (isComment) {
        def = {
          ...def,
          type: DATA_TYPES.textarea
        };
      } else {
        def = {
          ...def,
          type: DATA_TYPES.boolean
        };
      }
      break;
    case FormFieldType.CURRENCY:
      def =
        fieldDef.inputSize <= 1
          ? {
              ...def,
              type: DATA_TYPES.currency
            }
          : {
              ...def,
              type: DATA_TYPES.multiCurrency
            };
      break;
    case FormFieldType.NUMBER:
    case FormFieldType.PERCENTAGE:
      def = {
        ...def,
        type: DATA_TYPES.number
      };
      break;
    case FormFieldType.SELECT:
      def =
        fieldDef.inputSize <= 1
          ? {
              ...def,
              type: DATA_TYPES.select
            }
          : {
              ...def,
              type: DATA_TYPES.multiSelect
            };
      if (fieldDef.selectOptions) {
        def.options = fieldDef.selectOptions.map((opt) => ({
          label: opt,
          value: opt
        }));
      }
      break;
    default:
      break;
  }

  return def;
}

export function showToggle(fieldDef: FormFieldDefinitionModel): boolean {
  return fieldDef.type === FormFieldType.BOOLEAN;
}

export function showToggleWithComments(fieldDef: FormFieldDefinitionModel): boolean {
  return fieldDef.type === FormFieldType.YES_NO_COMMENTS;
}

export function showText(fieldDef: FormFieldDefinitionModel): boolean {
  return fieldDef.type === FormFieldType.TEXT && fieldDef.inputSize <= 1;
}

export function showTextArea(fieldDef: FormFieldDefinitionModel): boolean {
  return fieldDef.type === FormFieldType.TEXT && fieldDef.inputSize > 1;
}

export function showSectionHeader(fieldDef: FormFieldDefinitionModel): boolean {
  return fieldDef.type === FormFieldType.SECTION_HEADER;
}

export function showSingleCurrency(fieldDef: FormFieldDefinitionModel): boolean {
  return fieldDef.type === FormFieldType.CURRENCY && fieldDef.inputSize <= 1;
}

export function showMultiCurrency(fieldDef: FormFieldDefinitionModel): boolean {
  return fieldDef.type === FormFieldType.CURRENCY && fieldDef.inputSize > 1;
}

export function showSingleNumber(fieldDef: FormFieldDefinitionModel): boolean {
  return fieldDef.type === FormFieldType.NUMBER && fieldDef.inputSize <= 1;
}

export function showMultiNumber(fieldDef: FormFieldDefinitionModel): boolean {
  return fieldDef.type === FormFieldType.NUMBER && fieldDef.inputSize > 1;
}

export function showSinglePercent(fieldDef: FormFieldDefinitionModel): boolean {
  return fieldDef.type === FormFieldType.PERCENTAGE && fieldDef.inputSize <= 1;
}

export function showMultiPercent(fieldDef: FormFieldDefinitionModel): boolean {
  return fieldDef.type === FormFieldType.PERCENTAGE && fieldDef.inputSize > 1;
}

export function showSingleSelect(fieldDef: FormFieldDefinitionModel): boolean {
  return fieldDef.type === FormFieldType.SELECT && fieldDef.inputSize <= 1;
}

export function showMultiSelect(fieldDef: FormFieldDefinitionModel): boolean {
  return fieldDef.type === FormFieldType.SELECT && fieldDef.inputSize > 1;
}
