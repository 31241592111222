import { Component, Input } from '@angular/core';
import { faCertificate, faCheck, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { RmtCertification } from '@main/org/models/org.model';

@Component({
  selector: 'app-org-certified-icon',
  template: ` <div>
    @if (rmtCertification !== 'UNCERTIFIED') {
      <fa-stack
        [matTooltip]="rmtCertification === 'RMT_CERTIFIED_PLUS' ? 'RMT Certified + Partner' : 'RMT Certified Partner'"
        [matTooltipShowDelay]="300"
      >
        <fa-icon class="color-rmt-violet" stackItemSize="1x" [icon]="faCertificate"></fa-icon>
        <fa-icon class="color-white check-mark" stackItemSize="1x" [icon]="faCheck"></fa-icon>
      </fa-stack>
    }
    @if (rmtCertification === 'RMT_CERTIFIED_PLUS') {
      <fa-icon class="color-rmt-violet certified-plus" [icon]="faPlus"></fa-icon>
    }
  </div>`,
  styleUrls: ['./org-card.component.scss']
})
export class OrgCertifiedIconComponent {
  @Input() rmtCertification: RmtCertification;
  faPlus = faPlus;
  faCheck = faCheck;
  faCertificate = faCertificate;
}
