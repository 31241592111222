import { DiscoveredOrgModel } from '@main/org/models/org.model';
import { RfiFieldModel } from '@common/models/rfi-template.model';
import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';

// Need to keep track of the index so that we can match the sort coming from the server
export type SelectedPBM = { index: number; pbm: DiscoveredOrgModel };
export type PbmSelection = { allSelected: boolean; allFetched: boolean; selectedPbms: SelectedPBM[] };
export type RfiQuestionSelection = { includeCompanyOverview: boolean; selectedRfiQuestions: RfiFieldModel[] };
export type RfiSection = { section: RfiFieldModel; fields: RfiFieldModel[] };

export const orgOptions: DataDefModel = new DataDefModel({
  key: 'orgId',
  label: 'Select Organization',
  type: DATA_TYPES.select,
  validators: { required: true },
  asyncOptions: 'orgAsyncOptionGroup'
});

export const sysAdminOrgOptions: DataDefModel = new DataDefModel({
  key: 'orgId',
  label: 'Select Organization',
  type: DATA_TYPES.select,
  validators: { required: true },
  asyncOptions: 'orgs'
});
