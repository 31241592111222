import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RfiFieldDataModel, RfiTemplateModel } from '@common/models/rfi-template.model';
import { OrgModel } from '@main/org/models/org.model';
import { RfiService } from '@common/services/rfi.service';
import { faGlobeAmericas } from '@fortawesome/pro-solid-svg-icons';
import { faFacebookSquare, faInstagram, faLinkedin, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { map, tap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { RfiResponseModel } from '@common/models/rfi-response.model';
import { NotificationService } from '@shared/notifications/notification.service';

@Component({
  templateUrl: './rfi-response-dialog.component.html',
  styleUrls: ['./rfi-response-dialog.component.scss']
})
export class RfiResponseDialogComponent {
  rfiObservable$: Observable<[RfiResponseModel, RfiTemplateModel]> = combineLatest([
    this.rfiService.getRfiResponseForRequestedOrgs(this.data.callingOrgId, [this.data.requestedOrg.id]).pipe(
      // Map the 1 response into a single item for easier access from the observable
      map((response) => (!!response?.length ? response[0] : undefined)),
      tap((response) => setTimeout(() => (this.openAccordions = !!response), 250))
    ),
    this.rfiService.getRfiTemplateByOrg(this.data.requestedOrg.id)
  ]);

  rfiFieldDataToSave: RfiFieldDataModel[] = [];
  valid: boolean = false;
  faGlobeAmericas = faGlobeAmericas;
  faFacebookSquare = faFacebookSquare;
  faLinkedin = faLinkedin;
  faTwitterSquare = faTwitterSquare;
  faInstagram = faInstagram;
  openAccordions = false;
  buttonColor = 'primary';

  constructor(
    private rfiService: RfiService,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<RfiResponseDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      callingOrgId: number;
      requestedOrg: OrgModel;
      readOnly: boolean;
    }
  ) {}

  formUpdate(event: { rfiFieldData: RfiFieldDataModel[]; valid: boolean }) {
    this.rfiFieldDataToSave = this.rfiService.mapUIDataToServerData(event.rfiFieldData, this.data.callingOrgId);
    this.valid = event.valid;
  }

  submit(): void {
    if (this.valid) {
      this.buttonColor = 'primary';
      this.rfiService.updateRfiResponse(this.data.callingOrgId, this.rfiFieldDataToSave).subscribe((_) => {
        this.notificationService.successfulNotification('Profile updated.');
        this.dialogRef.close();
      });
    } else {
      this.buttonColor = 'warn';
    }
  }

  emptyDefault(toDisplay: any, defaultDisplay: string = '--') {
    return !!toDisplay ? toDisplay : defaultDisplay;
  }
}
