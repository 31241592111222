import { OrgFunction, OrgTypeModel } from '@common/models/org-type.model';
import { PorModel } from '@common/models/por.model';
import { TagModel } from '@configuration/admin/tag-list/tag.model';
import { FileModel } from '@file-upload-lib/file.model';
import { PreferredAccountModel } from '@common/models/preferred-accounts.model';
import { RapidQuoteRxProfileModel } from '@common/models/rapid-quote-rx-profile.model';

export enum FeatureType {
  dyXmlEnabled = 'DY_XML_ENABLED',
  intakeRoleEnabled = 'INTAKE_ROLE_ENABLED',
  levelFundedEnabled = 'LEVEL_FUNDED_ENABLED',
  quoteLinqEnabled = 'QUOTE_LINQ_ENABLED',
  quoteLinqRxEnabled = 'QUOTE_LINQ_RX_ENABLED',
  responseXmlEnabled = 'RESPONSE_XML_ENABLED',
  smartLinqEnabled = 'SMART_LINQ_ENABLED'
}

export function getLabelsForFeatures(item): String {
  const labels = [];
  const featureLabels = {
    [FeatureType.dyXmlEnabled]: 'ESL Manual XML Import',
    [FeatureType.intakeRoleEnabled]: 'Intake Role',
    [FeatureType.responseXmlEnabled]: 'RMT Manual XML Import',
    [FeatureType.quoteLinqEnabled]: 'Quote-LinQ',
    [FeatureType.quoteLinqRxEnabled]: 'Ringmaster Rx',
    [FeatureType.smartLinqEnabled]: 'Smart-LinQ',
    [FeatureType.levelFundedEnabled]: 'Level Funded'
  };

  const addLabels = (features) => {
    features?.forEach((f) => {
      if (featureLabels[f]) {
        labels.push(featureLabels[f]);
      }
    });
  };

  addLabels(item.value);
  addLabels(item.features);

  if (item.eslOfficeDomain) {
    labels.push('ESL Office');
  }

  return labels.sort().join(', ');
}

export enum RmtCertification {
  RMT_CERTIFIED_PLUS = 'RMT_CERTIFIED_PLUS',
  RMT_CERTIFIED = 'RMT_CERTIFIED',
  UNCERTIFIED = 'UNCERTIFIED'
}

export class OrgModel {
  public id?: number;
  public name?: string;
  public abbreviation?: string;
  public notes?: string;
  public rmtPanelMember?: boolean;
  public rmtTestOrg?: boolean;
  public rmtCertification?: RmtCertification;
  public active?: boolean;
  public orgType?: OrgTypeModel;
  public orgData?: any;
  public flaggedDrcs?: string[];
  public features?: FeatureType[];
  credentialedOrgs?: string[];
  public defaultTag?: TagModel;
  public imageRmtFile?: FileModel;
  public eslOfficeDomain?: string;
  public lddLastUpdated?: string;
  public sdlLastUpdated?: string;
  public primaryColor?: string;
  public secondaryColor?: string;
  public enterpriseClient?: boolean;
  public technologyFee?: number;
  public technologyFeeOneTime?: number;
  public lddSdlConflict?: boolean;

  constructor(
    id?: any,
    name?: string,
    abbreviation?: string,
    description?: string,
    rmtPanelMember?: boolean,
    rmtTestOrg?: boolean,
    rmtCertification?: RmtCertification,
    active?: boolean,
    orgType?: OrgTypeModel,
    orgData?: any,
    defaultTag?: TagModel,
    imageRmtFile?: FileModel,
    eslOfficeDomain?: string,
    lddLastUpdated?: string,
    sdlLastUpdated?: string,
    primaryColor?: string,
    secondaryColor?: string
  ) {
    this.id = id ? id : null;
    this.name = name ? name : null;
    this.abbreviation = abbreviation ? abbreviation : null;
    this.notes = description ? description : null;
    this.rmtPanelMember = rmtPanelMember ? rmtPanelMember : false;
    this.rmtTestOrg = rmtTestOrg ? rmtTestOrg : false;
    this.rmtCertification = rmtCertification ? rmtCertification : RmtCertification.UNCERTIFIED;
    this.active = active ? active : null;
    this.orgType = orgType ? orgType : new OrgTypeModel();
    this.orgData = orgData ? orgData : null;
    this.defaultTag = defaultTag ? defaultTag : null;
    this.imageRmtFile = imageRmtFile ? imageRmtFile : null;
    this.eslOfficeDomain = eslOfficeDomain ? eslOfficeDomain : null;
    this.lddLastUpdated = lddLastUpdated ? lddLastUpdated : null;
    this.sdlLastUpdated = sdlLastUpdated ? sdlLastUpdated : null;
    this.primaryColor = primaryColor ? primaryColor : null;
    this.secondaryColor = secondaryColor ? secondaryColor : null;
  }
}

export class OrgMinModel {
  id?: number;
  name?: string;
  abbreviation?: string;
  features?: FeatureType[];
  orgFunction?: OrgFunction;
  imageRmtFile?: FileModel;
  rmtPanelMember?: boolean;
  rmtTestOrg?: boolean;
  rmtCertification?: RmtCertification;
}

export class DiscoveredOrgModel extends OrgMinModel {
  public matchIndexLevel?: MatchIndexLevelType;
  public rapidQuoteRxProfile?: RapidQuoteRxProfileModel;
  public rmtTestOrg?: boolean;
  public rmtCertification?: RmtCertification;
}

export class OrgFeaturesModel {
  public rmtPanelMember: boolean;
  public rmtTestOrg?: boolean;
  public rmtCertification?: RmtCertification;
  public features: FeatureType[];
  public verikaiProfileId: string;
  public eslOfficeDomain: string;
  public orgTypeId: number;
  public quoteLinqExtUnderWriterId: number;
  public quoteLinqExtUnderWriterOrgRefId: string;
  public enterpriseClient: boolean;
  public technologyFee: number;
  public technologyFeeOneTime: number;
}

export enum MatchIndexLevelType {
  BRONZE = 'BRONZE',
  GOLD = 'GOLD',
  SILVER = 'SILVER'
}

export class CredentialedOrgModel {
  constructor(
    public org?: OrgModel,
    public credentialedOrg?: OrgMinModel,
    public por?: PorModel,
    public verikaiEnabled?: boolean,
    public matchIndexLevel?: MatchIndexLevelType,
    public preferredAccount?: PreferredAccountModel,
    public rapidQuoteRxProfiles?: RapidQuoteRxProfileModel[]
  ) {}
}

export class OrgNotification {
  constructor(
    public id?: number,
    public orgId?: number,
    public email?: string,
    public daysLastImport?: number,
    public importFailure?: boolean,
    public importSummary?: boolean,
    public enableDaysLastImport?: boolean // a meta field only used in the UI for editing purposes
  ) {}
}
