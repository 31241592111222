import { NgModule } from '@angular/core';
import { OrgCardComponent } from '@common/components/org-card/org-card.component';
import { OrgLogoModule } from '@common/components/org-logo/org-logo.module';
import { SharedModule } from '@shared/shared.module';
import { OrgCertifiedIconComponent } from '@common/components/org-card/org-certified-icon.component';

@NgModule({
  declarations: [OrgCardComponent, OrgCertifiedIconComponent],
  imports: [OrgLogoModule, SharedModule],
  exports: [OrgCardComponent, OrgCertifiedIconComponent]
})
export class OrgCardModule {}
