<div class="rfi-vertical-panel">
  <div class="rfi-vertical-panel-header">
    <ng-content select="[panel-handle]"></ng-content>
    <div class="rfi-vertical-panel-org">
      <app-org-logo
        class="rfi-vertical-panel-org-logo"
        [org]="{
          id: rfiResponse.orgId,
          name: rfiResponse.orgName,
          imageRmtFile: rfiResponse.imageRmtFile
        }"
      ></app-org-logo>
      <div class="rfi-vertical-panel-org-name-and-certification">
        <span class="rfi-vertical-panel-header-org-name">{{ rfiResponse.orgName }}</span>
        <app-org-certified-icon [rmtCertification]="rfiResponse.rmtCertification"></app-org-certified-icon>
      </div>
    </div>
    <ng-content select="[panel-action]"></ng-content>
  </div>
  @if (includeCompanyOverview) {
    <div class="rfi-vertical-panel-company-overview bg-mid-gray">
      <!-- Company Overview -->
      <div class="rfi-vertical-panel-section-spacer"></div>
      <!-- Service -->
      <div
        [matTooltip]="'Click to view full answer'"
        [matTooltipShowDelay]="500"
        (click)="openOverviewPopover($event, 'Overview', emptyDefault(rfiResponse.services))"
        class="rfi-vertical-panel-text-area clickable"
      >
        {{ emptyDefault(rfiResponse.services) }}
      </div>
      <!-- Specialties -->
      <div
        [matTooltip]="'Click to view full answer'"
        [matTooltipShowDelay]="500"
        (click)="openOverviewPopover($event, 'Specialties', emptyDefault(rfiResponse.specialties))"
        class="rfi-vertical-panel-text-area clickable"
      >
        {{ emptyDefault(rfiResponse.specialties) }}
      </div>
      <!-- Headquarters -->
      <div
        [matTooltip]="emptyDefault(rfiResponse.headquarters)"
        [matTooltipShowDelay]="500"
        class="rfi-vertical-panel-answer text-no-overflow"
      >
        {{ emptyDefault(rfiResponse.headquarters) }}
      </div>
      <!-- Company size -->
      <div
        [matTooltip]="emptyDefault(rfiResponse.companySize)"
        [matTooltipShowDelay]="500"
        class="rfi-vertical-panel-answer text-no-overflow"
      >
        {{ emptyDefault(rfiResponse.companySize) }}
      </div>
      <!-- Founded -->
      <div
        [matTooltip]="emptyDefault(rfiResponse.founded)"
        [matTooltipShowDelay]="500"
        class="rfi-vertical-panel-answer text-no-overflow"
      >
        {{ emptyDefault(rfiResponse.founded) }}
      </div>
      <!-- Ownership Structure -->
      <div
        [matTooltip]="emptyDefault(rfiResponse.ownershipStructure)"
        [matTooltipShowDelay]="500"
        class="rfi-vertical-panel-answer text-no-overflow"
      >
        {{ emptyDefault(rfiResponse.ownershipStructure) }}
      </div>
      <!-- Website -->
      <div
        [matTooltip]="emptyDefault(rfiResponse.website)"
        [matTooltipShowDelay]="500"
        class="rfi-vertical-panel-answer text-no-overflow"
      >
        {{ emptyDefault(rfiResponse.website) }}
      </div>
      <!-- LinkedIn -->
      <div
        [matTooltip]="emptyDefault(rfiResponse.linkedin)"
        [matTooltipShowDelay]="500"
        class="rfi-vertical-panel-answer text-no-overflow"
      >
        {{ emptyDefault(rfiResponse.linkedin) }}
      </div>
      <!-- Facebook -->
      <div
        [matTooltip]="emptyDefault(rfiResponse.facebook)"
        [matTooltipShowDelay]="500"
        class="rfi-vertical-panel-answer text-no-overflow"
      >
        {{ emptyDefault(rfiResponse.facebook) }}
      </div>
      <!-- X (Formerly Twitter) -->
      <div
        [matTooltip]="emptyDefault(rfiResponse.twitter)"
        [matTooltipShowDelay]="500"
        class="rfi-vertical-panel-answer text-no-overflow"
      >
        {{ emptyDefault(rfiResponse.twitter) }}
      </div>
      <!-- Instagram -->
      <div
        [matTooltip]="emptyDefault(rfiResponse.instagram)"
        [matTooltipShowDelay]="500"
        class="rfi-vertical-panel-answer text-no-overflow"
      >
        {{ emptyDefault(rfiResponse.instagram) }}
      </div>
    </div>
  }
  <div class="rfi-vertical-panel-body">
    @for (answer of rfiAnswersToShow; track answer) {
      @if (answer.rfiField.formFieldDefinition.type === 'SECTION_HEADER') {
        <div class="rfi-vertical-panel-section-spacer"></div>
      } @else {
        <div
          [matTooltip]="
            isTextArea(answer.rfiField.formFieldDefinition)
              ? 'Click to view full answer'
              : (mapAnswerToValue(answer)
                | dataFormatter: getDataDefForAnswer(answer.rfiField.formFieldDefinition) : '--')
          "
          [matTooltipShowDelay]="500"
          (click)="openPopover($event, answer)"
          class="rfi-answer"
          [ngClass]="
            isTextArea(answer.rfiField.formFieldDefinition)
              ? 'rfi-vertical-panel-text-area clickable'
              : 'rfi-vertical-panel-answer text-no-overflow'
          "
        >
          {{
            mapAnswerToValue(answer) | dataFormatter: getDataDefForAnswer(answer.rfiField.formFieldDefinition) : '--'
          }}
        </div>
      }
    }
  </div>
</div>
