import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { SERVER_API_URL } from '@app/app.constants';
import { OrgFunction } from '@common/models/org-type.model';
import { Page } from '@common/models/page.model';
import { QuoteTypeService } from '@common/services/quote-type.service';
import { UrlUtilService } from '@common/services/url-util.service';
import { FileDocType, FileModel } from '@file-upload-lib/file.model';
import { FormModel } from '@form-lib/models/form.model';
import { DATA_TYPES, DataDefModel, DataDefOption } from '@lib-resource/data-def.model';
import { HttpResourceService } from '@main/http-resource.service';
import { SiteFilterOptions } from '@main/store/site-filter/site-filter.models';
import { RfpConfirmationDialogData, RpfConfirmationDialogType } from '@common/components/rfp-confirmation-dialog/rfp-confirmation-dialog.model';
import { MessageModel } from '@common/components/message/message.model';
import { AdminFeeModel } from '@quote/model/admin-fee.model';
import {
  brokerNewProspectClosedReasonOptions,
  brokerNewProspectFormValidation,
  brokerRenewalClosedReasonOptions,
  brokerRenewalFormValidation,
  CopySupportFileRequestModel,
  fundingTypeOptions,
  QuoteRequestBusinessType,
  QuoteRequestModel,
  QuoteRequestReason,
  QuoteRequestReasonChangeModel,
  QuoteRequestStatus,
  rfpAllCloseReasonDef,
  rxClosedReasonOptions,
  rxFormValidation,
  StopLossSoldSheetFinalTermsModel,
  tpaNewProspectClosedReasonOptions,
  tpaNewProspectFormValidation,
  tpaRenewalClosedReasonOptions,
  tpaRenewalFormValidation
} from '@common/models/quote-request.model';
import { QuoteResponseMinModel, QuoteResponseOptionStatusChange } from '@common/models/quote-response.model';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CredentialedOrgModel } from '@main/org/models/org.model';
import { FilterUtilService } from '@common/services/filter-util.service';
import { getUTCFormattedDateString } from '@app/tools/date';
import { QuoteTypeProduct } from '@common/models/quote-type.model';
import { BulkActionModel } from '@common/models/bulk-action.model';
import { FileDownloadService, HttpRequestType } from '@shared/components/file-download-progress/file-download.service';
import { ReportInstanceModel } from '@common/models/report-instance.model';
import { QuoteRequestCommentModel } from '@common/models/quote-request-comment.model';
import { AccountModel } from '@common/models/account.model';
import { CreateQuoteRequestWithFilesModel } from '@common/models/create-intake-quote-request.model';
import { FileItem } from 'ng2-file-upload';
import { cleanLocalDates } from '@lib-resource/data.utils';
import { FundingTypeModel } from '@common/models/funding-type.model';

const siteFilterConfig = [
  {
    siteFilterKey: SiteFilterOptions.ORG_MEMBERSHIP,
    dataKey: 'quotetype.org.id'
  },
  {
    siteFilterKey: SiteFilterOptions.ACCOUNT,
    dataKey: 'account.id'
  }
];

export class QuoteRequestStatusChange {
  quoteResponseOptionIds: number[];
  status?: QuoteRequestStatus;
}

@Injectable({
  providedIn: 'root'
})
export class QuoteRequestService {
  private urlUtil = new UrlUtilService();

  constructor(
    protected http: HttpClient,
    private fileDownloadService: FileDownloadService,
    private httpResource: HttpResourceService,
    private quoteTypeService: QuoteTypeService,
    private filterUtilService: FilterUtilService
  ) {}

  searchQuoteRequests(
    filterString: string,
    pageIndex = 0,
    pageSize = 0,
    sort: string[] = []
  ): Observable<Page<QuoteRequestModel>> {
    return this.httpResource.queryWithoutOrg({
      path: 'quote-request',
      query: { filterString: this.filterUtilService.filterByQuoteProduct(filterString), pageIndex, pageSize, sort },
      siteFilterConfig: siteFilterConfig
    });
  }

  searchQuoteRequestsWithOutSiteFilter(
    filterString: string,
    pageIndex = 0,
    pageSize = 0,
    sort: string[] = []
  ): Observable<Page<QuoteRequestModel>> {
    return this.http.get<Page<QuoteRequestModel>>(`${SERVER_API_URL}/quote-request`, {
      params: this.urlUtil.buildSearchParams(
        this.filterUtilService.filterByQuoteProduct(filterString),
        pageIndex,
        pageSize,
        sort
      )
    });
  }

  setQuoteRequestTags(quoteRequest: QuoteRequestModel, tagIds: Array<number>, notify: boolean = true): Observable<any> {
    return this.http.put(
      `${SERVER_API_URL}/quote-request/${quoteRequest.id}/tag?notify=${notify}&tagIds=${
        tagIds ? tagIds.map((t) => t).join(',') : ''
      }`,
      null
    );
  }

  updateQuoteRequest(quoteRequest: QuoteRequestModel): Observable<QuoteRequestModel> {
    return this.convertCurrencyFieldsToNumericAndSaveQuoteRequest(quoteRequest, HttpRequestType.PUT);
  }

  updateQuoteRequestBaseline(quoteRequest: QuoteRequestModel, baselineVersionId: number, baselineOptionId: number): Observable<QuoteRequestModel> {
    let params = new HttpParams();
    if (!!baselineVersionId) {
      params = params.append('baselineVersionId', baselineVersionId);
    }
    if (!!baselineOptionId) {
      params = params.append('baselineOptionId', baselineOptionId);
    }
    return this.http.put(`${SERVER_API_URL}/quote-request/${quoteRequest.id}/baseline`, null, {params: params});
  }

  createQuoteRequest(
    quoteRequest: QuoteRequestModel,
    copySupportFileRequest: CopySupportFileRequestModel
  ): Observable<QuoteRequestModel> {
    return this.convertCurrencyFieldsToNumericAndSaveQuoteRequest(
      quoteRequest,
      HttpRequestType.POST,
      copySupportFileRequest
    );
  }

  createQuoteRequestWithFiles(
    quoteRequest: QuoteRequestModel,
    newAccount: AccountModel,
    fieldDefs: DataDefModel[],
    quoteRequestComments: QuoteRequestCommentModel[],
    supportingFiles: FileItem[],
    copySupportFileRequest: CopySupportFileRequestModel
  ): Observable<QuoteRequestModel> {
    const currencyFields: Set<string> = new Set<string>();
    this.findCurrencyFieldsInFormLayout({ fields: fieldDefs }, null, currencyFields);
    currencyFields.forEach((cf) => {
      const currencyFieldPath: Array<string> = cf.split('.'); // i.e. 'quoteData.terms.aggSpec' split into 'quoteData', 'terms', 'aggSpec'
      this.convertCurrencyFieldToNumericForQuoteData(quoteRequest, currencyFieldPath);
    });

    cleanLocalDates(quoteRequest);

    const createQuoteRequestModel: CreateQuoteRequestWithFilesModel = {
      quoteRequest: quoteRequest,
      newAccount: newAccount,
      quoteRequestComments: quoteRequestComments,
      attachedSupportFiles: [],
      copySupportFileRequest: copySupportFileRequest
    };

    const formData: FormData = new FormData();
    supportingFiles?.forEach((supportingFile) => {
      createQuoteRequestModel.attachedSupportFiles.push({
        name: supportingFile._file.name,
        description: supportingFile.formData.description,
        docType: FileDocType[supportingFile.formData.docType] || FileDocType.OTHER
      });
      formData.append(supportingFile.file.name, supportingFile._file, supportingFile.file.name);
    });
    formData.append('createDTO', new Blob([JSON.stringify(createQuoteRequestModel)], { type: 'application/json' }));

    return this.http.post(
      `${SERVER_API_URL}/quote-request/org/${quoteRequest.quoteType.orgId}/request/${quoteRequest.quoteType.id}`,
      formData
    );
  }

  /*
    Before sending the QuoteRequest to the server, inspect the 'currency' type fields in the 'quoteData' object.
    Get the QuoteType object from the server so that the reqFormLayout can be obtained. Find all 'fields' in the
    reqFormLayout that are of type 'currency'.
    All these 'currency' field values are coming in as text values from the UI form.
    Convert 'currency' fields to numeric before sending to the server.
  */
  convertCurrencyFieldsToNumericAndSaveQuoteRequest(
    quoteRequest: QuoteRequestModel,
    httpType: HttpRequestType,
    copySupportFileReq?: CopySupportFileRequestModel
  ): Observable<QuoteRequestModel> {
    return this.quoteTypeService.getQuoteType(quoteRequest.quoteType.id, quoteRequest.quoteType.orgId).pipe(
      switchMap((quoteType) => {
        const currencyFields: Set<string> = new Set<string>();
        this.findCurrencyFieldsInFormLayout(quoteType.reqFormLayout, null, currencyFields);
        currencyFields.forEach((cf) => {
          const currencyFieldPath: Array<string> = cf.split('.'); // i.e. 'quoteData.terms.aggSpec' split into 'quoteData', 'terms', 'aggSpec'
          this.convertCurrencyFieldToNumericForQuoteData(quoteRequest, currencyFieldPath);
        });

        cleanLocalDates(quoteRequest);

        if (httpType === HttpRequestType.PUT) {
          return this.http.put(`${SERVER_API_URL}/quote-request/${quoteRequest.id}`, quoteRequest);
        }

        const createQuoteRequestModel = {
          quoteRequest: quoteRequest,
          copySupportFileRequest: copySupportFileReq
        };
        return this.http.post(
          `${SERVER_API_URL}/quote-request/org/${quoteRequest.quoteType.orgId}/quote-type/${quoteRequest.quoteType.id}`,
          createQuoteRequestModel
        );
      })
    );
  }

  convertCurrencyFieldToNumericForQuoteData(obj, propertyNames: Array<string>) {
    if (!!obj) {
      let parentObj = obj;
      let propertyName;
      for (let i = 0; i < propertyNames.length && !!obj; i++) {
        parentObj = obj;
        propertyName = propertyNames[i];
        obj = obj[propertyName];
        if (!!obj && Array.isArray(obj)) {
          obj.forEach((arrayObj) =>
            this.convertCurrencyFieldToNumericForQuoteData(arrayObj, propertyNames.slice(i + 1))
          );
        }
      }
      // The 'obj' contains the value of the currency field. Save it as numeric.
      if (!!obj) {
        parentObj[propertyName] = +obj;
      } else if (obj === '') {
        parentObj[propertyName] = null;
      }
    }
  }

  findCurrencyFieldsInFormLayout(
    parentNode: FormModel | DataDefModel,
    fieldNamePrefix: string,
    currencyFields: Set<string>
  ) {
    if (!!parentNode && parentNode?.fields?.length) {
      parentNode.fields
        .filter((f) => f.type === DATA_TYPES.currency)
        .map((d) => (!!fieldNamePrefix ? fieldNamePrefix + '.' + d.key : d.key))
        .forEach((cf) => currencyFields.add(cf));
      // find form group arrays and add currency fields that they may contain.
      const fgaList: Array<DataDefModel> = parentNode.fields.filter((f) => f.type === DATA_TYPES.formGroupArray);
      fgaList.forEach((fga) => this.findCurrencyFieldsInFormLayout(fga, fga.key, currencyFields));
    }
  }

  getById(id: number): Observable<QuoteRequestModel> {
    return this.http.get(`${SERVER_API_URL}/quote-request/${id}`);
  }

  delete(id: number): Observable<QuoteRequestModel> {
    return this.http.delete(`${SERVER_API_URL}/quote-request/${id}`);
  }

  getSupportFiles(id: number): Observable<FileModel[]> {
    return this.http.get<FileModel[]>(`${SERVER_API_URL}/quote-request/${id}/supportfile`);
  }

  downloadSupportFile(id: number, fileId: string, fileName: string): Observable<Blob> {
    const path = `quote-request/${id}/supportfile/${fileId}/stream`;
    return this.httpResource.downloadFileWithoutOrg({ path }, { fileName });
  }

  deleteSupportFile(qrId: number, fileId: string): Observable<void> {
    return this.http.delete<void>(`${SERVER_API_URL}/quote-request/${qrId}/supportfile/${fileId}`);
  }

  updateSupportFileMetaData(
    qrId: number,
    fileId: string,
    name: string,
    docType: FileDocType,
    desc: string
  ): Observable<FileModel> {
    let params = new HttpParams({
      fromObject: {
        name: name,
        docType: docType
      }
    });
    if (desc) {
      params = new HttpParams({
        fromObject: {
          name: name,
          docType: docType,
          description: desc
        }
      });
    }
    return this.http.put<FileModel>(`${SERVER_API_URL}/quote-request/${qrId}/supportfile/${fileId}`, null, {
      params: params
    });
  }

  getAuthQuoteRequestProposal(
    quoteRequestId: number,
    quoteResponseOptions: number[],
    acceptHeader: string,
    fileName: string
  ): Observable<Blob> {
    let params = new HttpParams();
    quoteResponseOptions.forEach((responseOption) => {
      params = params.append('quoteOptionIds', '' + responseOption);
    });

    return this.fileDownloadService.downloadFile(`${SERVER_API_URL}/quote-request/${quoteRequestId}/proposal/doc`, {
      fileName,
      params
    });
  }

  cancelRequest(quoteRequestId: number, closedReason: QuoteRequestReasonChangeModel): Observable<void> {
    return this.http.put<void>(`${SERVER_API_URL}/quote-request/${quoteRequestId}/cancel`, closedReason);
  }

  bindRequest(quoteRequestId: number, terms: StopLossSoldSheetFinalTermsModel): Observable<QuoteRequestModel> {
    return this.http.put<QuoteRequestModel>(`${SERVER_API_URL}/quote-request/${quoteRequestId}/bind`, terms);
  }

  updateReason(
    quoteRequestId: number,
    reason: QuoteRequestReason,
    reasonText: string,
    quoteResponseOrgId: number,
    underWriter: string,
    fundingType: FundingTypeModel
  ): Observable<any> {
    return this.http.put<QuoteRequestModel>(`${SERVER_API_URL}/quote-request/${quoteRequestId}/reason`, {
      reason: reason,
      reasonText: reasonText,
      quoteResponseOrgId: quoteResponseOrgId,
      underWriter: underWriter,
      fundingType: fundingType
    });
  }

  updateFinalTerms(quoteRequestId: number, terms: StopLossSoldSheetFinalTermsModel): Observable<QuoteRequestModel> {
    return this.http.put<QuoteRequestModel>(`${SERVER_API_URL}/quote-request/${quoteRequestId}/final-terms`, terms);
  }

  awardContract(
    quoteRequestId: number,
    optionId: number,
    winningTerms: StopLossSoldSheetFinalTermsModel
  ): Observable<void> {
    return this.http.put<void>(
      `${SERVER_API_URL}/quote-request/${quoteRequestId}/option/${optionId}/awardcontract`,
      winningTerms
    );
  }

  reopen(quoteRequestId: number): Observable<QuoteRequestModel> {
    return this.http.put<QuoteRequestModel>(`${SERVER_API_URL}/quote-request/${quoteRequestId}/reopen`, null);
  }

  getAdminFees(quoteRequestId: number): Observable<AdminFeeModel[]> {
    return this.http.get<AdminFeeModel[]>(`${SERVER_API_URL}/quote-request/${quoteRequestId}/adminfee`);
  }

  saveAdminFees(quoteRequestId: number, adminFees: Array<AdminFeeModel>): Observable<AdminFeeModel[]> {
    return this.http.put<AdminFeeModel[]>(`${SERVER_API_URL}/quote-request/${quoteRequestId}/adminfee`, adminFees);
  }

  getManifestOptions(quoteRequestId: number) {
    return this.httpResource.getWithoutOrg(`quote-request/${quoteRequestId}/manifest`);
  }

  downloadCoverSheet(quoteRequestId: number, quoteResponseId: string): Observable<Blob> {
    let params = new HttpParams();
    if (!!quoteResponseId) {
      params = params.append('quoteResponseId', quoteResponseId);
    }
    return this.httpResource.downloadFileWithoutOrg(
      { path: `quote-request/${quoteRequestId}/rfp-cover-sheet` },
      { params: params, fileName: null }
    );
  }

  downloadQuoteRequestPackage(quoteRequestId: number, quoteResponseId: string, fileName: string, qrPackage) {
    let params = new HttpParams();
    if (!!quoteResponseId) {
      params = params.append('quoteResponseId', quoteResponseId);
    }
    return this.httpResource.downloadFileWithoutOrg(
      { path: `quote-request/${quoteRequestId}/manifest/download` },
      { body: qrPackage, params: params, httpType: HttpRequestType.POST, fileName }
    );
  }

  updateStatus(quoteRequestId, updates: QuoteResponseOptionStatusChange[]) {
    return this.httpResource.putWithoutOrg(`quote-request/${quoteRequestId}/options/status`, updates);
  }

  addToShortlist(quoteRequestId, updates: QuoteRequestStatusChange) {
    return this.httpResource.putWithoutOrg(`quote-request/${quoteRequestId}/addtoshortlist`, updates);
  }

  createResponseMessages(quoteRequestId: number, messageAndFiles: FormData): Observable<MessageModel[]> {
    return this.httpResource.postWithoutOrg(`quote-request/${quoteRequestId}/bulk-message`, messageAndFiles);
  }

  bulkAction(bulkAction: BulkActionModel): Observable<{}> {
    return this.http.put(`${SERVER_API_URL}/quote-request/bulk-action`, bulkAction);
  }

  buildClosedReasonOptions(quoteRequest: QuoteRequestModel, viewOnly = false): DataDefOption[] {
    if (viewOnly) {
      return rfpAllCloseReasonDef.options;
    }
    if (quoteRequest.quoteType.product === QuoteTypeProduct.RX) {
      return rxClosedReasonOptions;
    }
    if (quoteRequest.quoteType.orgFunction === OrgFunction.BROKER && quoteRequest.renewal) {
      return brokerRenewalClosedReasonOptions;
    }
    if (quoteRequest.quoteType.orgFunction === OrgFunction.BROKER && !quoteRequest.renewal) {
      return brokerNewProspectClosedReasonOptions;
    }
    if (quoteRequest.quoteType.orgFunction === OrgFunction.TPA && quoteRequest.renewal) {
      return tpaRenewalClosedReasonOptions;
    }
    if (quoteRequest.quoteType.orgFunction === OrgFunction.TPA && !quoteRequest.renewal) {
      return tpaNewProspectClosedReasonOptions;
    }
    return [];
  }

  buildClosedReasonValidators(quoteRequest: QuoteRequestModel): ValidatorFn[] {
    if (!quoteRequest) {
      return [this.buildFormValidatorForReasonModal()];
    }
    if (quoteRequest.quoteType.product === QuoteTypeProduct.RX) {
      return [rxFormValidation, this.buildFormValidatorForReasonModal()];
    }
    if (quoteRequest.quoteType.orgFunction === OrgFunction.BROKER && quoteRequest.renewal) {
      return [brokerRenewalFormValidation, this.buildFormValidatorForReasonModal()];
    }
    if (quoteRequest.quoteType.orgFunction === OrgFunction.BROKER && !quoteRequest.renewal) {
      return [brokerNewProspectFormValidation, this.buildFormValidatorForReasonModal()];
    }
    if (quoteRequest.quoteType.orgFunction === OrgFunction.TPA && quoteRequest.renewal) {
      return [tpaRenewalFormValidation, this.buildFormValidatorForReasonModal()];
    }
    if (quoteRequest.quoteType.orgFunction === OrgFunction.TPA && !quoteRequest.renewal) {
      return [tpaNewProspectFormValidation, this.buildFormValidatorForReasonModal()];
    }
    return [];
  }

  buildDialogDataForCloseReasonModal(quoteRequest: QuoteRequestModel): RfpConfirmationDialogData {
    const isRx = quoteRequest.quoteType.product === QuoteTypeProduct.RX;
    const closedOptions = this.buildClosedReasonOptions(quoteRequest);
    const formValidators: ValidatorFn[] = this.buildClosedReasonValidators(quoteRequest);
    const dialogData: RfpConfirmationDialogData = {
      quoteRequest: quoteRequest,
      dialogType: RpfConfirmationDialogType.CLOSE,
      formModel: {
        sections: [{ key: 'closedReason', label: null }],
        fields: [
          new DataDefModel({
            label: 'Reason',
            key: 'reason',
            sectionKey: 'closedReason',
            validators: { required: true },
            type: DATA_TYPES.select,
            options: closedOptions,
            onChangeFn: this.buildReasonFieldOnChangesForReasonModal()
          }),
          new DataDefModel({
            label: 'Additional Details',
            sectionKey: 'closedReason',
            key: 'reasonText',
            type: DATA_TYPES.textarea
          })
        ]
      },
      formValidators: formValidators
    };
    if (!isRx) {
      dialogData.formModel.fields.push(
        new DataDefModel({
          key: 'fundingType',
          label: 'Funding Type',
          type: DATA_TYPES.select,
          options: fundingTypeOptions,
          sectionKey: 'closedReason',
          validators: {
            required: true
          }
        }),
        new DataDefModel({
          key: 'quoteResponseOrgId',
          label: 'Underwriter',
          type: DATA_TYPES.select,
          sectionKey: 'closedReason',
          disablers: []
        }),
        new DataDefModel({
          key: 'underWriter',
          label: 'Underwriter (Other)',
          sectionKey: 'closedReason',
          type: DATA_TYPES.text
        })
      );
    }
    return dialogData;
  }

  buildReasonFieldOnChangesForReasonModal() {
    return (control) => {
      const quoteResponseOrgIdControl = control?.parent?.controls.quoteResponseOrgId;
      if (!!quoteResponseOrgIdControl) {
        if (
          !control?.value ||
          (control?.value !== QuoteRequestReason.PLACED_OUTSIDE_QUOTE_LINQ &&
            control?.value !== QuoteRequestReason.RENEWED_SAME_CARRIER)
        ) {
          quoteResponseOrgIdControl.setValue(null);
          quoteResponseOrgIdControl.disable({ onlySelf: true, emitEvent: false });
          quoteResponseOrgIdControl.updateValueAndValidity();
        } else {
          quoteResponseOrgIdControl.enable({ onlySelf: true, emitEvent: false });
          quoteResponseOrgIdControl.setValidators(Validators.required);
          quoteResponseOrgIdControl.markAsTouched();
          quoteResponseOrgIdControl.updateValueAndValidity();
        }
      }
    };
  }

  buildQuoteResponseIdFieldOnChanges(otherKey: string) {
    return (control) => {
      const underwriterField = control.parent.controls[otherKey];
      if (!!underwriterField && control.value === -1) {
        underwriterField.setValidators([Validators.required]);
        underwriterField.markAsTouched(true);
        underwriterField.updateValueAndValidity();
      } else {
        underwriterField.clearValidators();
        underwriterField.setValue(null);
        underwriterField.updateValueAndValidity();
      }
    };
  }

  buildFormValidatorForReasonModal() {
    return (form: UntypedFormGroup): ValidationErrors | null => {
      const reason = form.controls.reason;
      const uw = form.controls.underWriter;
      const qr = form.controls.quoteResponseOrgId;

      if (uw && qr) {
        if (reason && !reason.value) {
          uw.disable({ onlySelf: true, emitEvent: false });
          qr.disable({ onlySelf: true, emitEvent: false });
        } else if (qr.value === -1) {
          uw.enable({ onlySelf: true, emitEvent: false });
        } else {
          uw.disable({ onlySelf: true, emitEvent: false });
          if (!!uw.value) {
            uw.setValue(null);
          }
        }
      }
      return null;
    };
  }

  modifyQuoteResponseOrgIdField(defs: DataDefModel[], creds: CredentialedOrgModel[], otherKey: string) {
    return defs.map((def) => {
      if (def.key === 'quoteResponseOrgId') {
        def.options = creds.map((o) => ({ label: o.credentialedOrg.name, value: o.credentialedOrg.id }));
        def.options.push({ label: 'Other, please specify', value: -1 });
        def.onChangeFn = this.buildQuoteResponseIdFieldOnChanges(otherKey);
      }
      return def;
    });
  }

  calcCompositeEnrollment(quoteRequest: QuoteRequestModel): number {
    const enrollment: number[] = [
      quoteRequest.enrollmentEmployeeOnly,
      quoteRequest.enrollmentEmployeeSpouse,
      quoteRequest.enrollmentEmployeeChildren,
      quoteRequest.enrollmentEmployeeDependent,
      quoteRequest.enrollmentFamily
    ];

    return enrollment
      .map((v) => (isNaN(v) ? 0 : v))
      .reduce((acc, val) => {
        acc += Number(val);
        return acc;
      }, 0);
  }

  calcParticipantPercent(quoteRequest: QuoteRequestModel): number {
    const enrollment = this.calcCompositeEnrollment(quoteRequest);
    const totalEligible = quoteRequest?.enrollmentTotalEligibleEmployees;
    if (totalEligible <= 0 || isNaN(totalEligible) || isNaN(enrollment)) {
      return 0;
    }
    return (enrollment / totalEligible) * 100;
  }

  buildFinancialExhibitFilename(quoteRequest: QuoteRequestModel): string {
    const now = getUTCFormattedDateString(new Date(), 'yyyyMMdd');
    return `${quoteRequest.account.name} Request ID ${quoteRequest.id} - Financial Exhibit ${now}.xlsx`;
  }

  findQuoteRequestReportInstance(
    quoteRequestId: number,
    filterString: string,
    pageIndex: number,
    pageSize: number,
    sort: string[]
  ): Observable<Page<ReportInstanceModel>> {
    return this.httpResource.queryWithoutOrg({
      path: `quote-request/${quoteRequestId}/report/instance`,
      query: { filterString, pageIndex, pageSize, sort }
    });
  }

  getComments(quoteRequestId: number): Observable<QuoteRequestCommentModel[]> {
    return this.http.get<QuoteRequestCommentModel[]>(`${SERVER_API_URL}/quote-request/${quoteRequestId}/comment`);
  }

  getCommentsOldestFirst(quoteRequestId: number): Observable<QuoteRequestCommentModel[]> {
    return this.getComments(quoteRequestId).pipe(map((comments) => comments.reverse()));
  }

  addComment(quoteRequestId: number, comment: QuoteRequestCommentModel) {
    return this.http.post(`${SERVER_API_URL}/quote-request/${quoteRequestId}/comment`, comment);
  }

  updateComment(quoteRequestId: number, comment: QuoteRequestCommentModel) {
    return this.http.put(`${SERVER_API_URL}/quote-request/${quoteRequestId}/comment/${comment.id}`, comment);
  }

  deleteComment(quoteRequestId: number, commentId: number) {
    return this.http.delete(`${SERVER_API_URL}/quote-request/${quoteRequestId}/comment/${commentId}`);
  }

  transformBusinessType(
    currentBusinessType: QuoteRequestBusinessType,
    responses: QuoteResponseMinModel[]
  ): QuoteRequestBusinessType {
    if (
      currentBusinessType === QuoteRequestBusinessType.INCUMBENT_RENEWAL ||
      currentBusinessType === QuoteRequestBusinessType.RENEWAL
    ) {
      return QuoteRequestBusinessType.NEW_PROSPECT;
    }
    if (currentBusinessType === QuoteRequestBusinessType.NEW_PROSPECT) {
      if (responses.length === 0 || (responses.length === 1 && responses[0].incumbent)) {
        return QuoteRequestBusinessType.INCUMBENT_RENEWAL;
      }
      if (responses.length > 1 || (responses.length === 1 && !responses[0].incumbent)) {
        return QuoteRequestBusinessType.RENEWAL;
      }
    }
  }
}
